import React from "react";
import {Tooltip} from "@material-ui/core";
import {RemoveRedEye} from "@material-ui/icons";

//components
import Table from "./Table";
import Button from "../CustomButtons/Button";
import {Link} from "react-router-dom";


const ResultsEventsTable=({events,link})=>{

    const TableHead=['Event','Details','Date','Actions']

    const TableData=events.reduce((a,v)=>{

        const {title,logo,venue,date}=v.data;//data & event id
        a.push([
            <img alt={title+' logo'} src={logo.url} height={30} />,
            <div>
                <p style={{fontSize:'1em'}}>{title}</p>
                <p style={{fontSize:'0.8em'}}>{venue}</p>
            </div>,
            <p style={{fontSize:'1em'}}>{date}</p>,
            <Link to={link+`/${v.id}`}>
                <Tooltip placement={'bottom'} title={'View Event'}>
                    <Button justIcon color={'info'} size={'sm'} round >
                        <RemoveRedEye/>
                    </Button>
                </Tooltip>
            </Link>

        ])

        return a;
    },[])


    return (
        <Table
            tableHeaderColor={'info'}
            tableHead={TableHead}
            tableData={TableData}
        />

    )
}

export default ResultsEventsTable;