import {RESULTS_EVENTS, LOAD_EVENT_RESULTS, LOAD_PRIVACY,LOAD_TERMS} from "../types";
import axios from 'axios';
import {dbFire} from "../../Firebase/index";

export const getPublishedEvents =(callback)=>async(dispatch)=>{

    const response = await axios.get('https://us-central1-dance-suite.cloudfunctions.net/eventsResults');

    if(response.data.status===1){

        const events = response.data.data;

        const eventDates = Object.keys(events).reduce((a,v)=>{

            const {date}=events[v];

            if(a[date]){
                a[date].push(v)
            }else{
                a[date]=[v]
            }


            return a
        },{});



        const sortedEvents = Object.keys(eventDates).sort().reverse().reduce((a,v)=>{

            Object.keys(events).map(id=>{

                if(eventDates[v].includes(id)){
                    a.push(
                        {id:id,data:events[id]}
                    )
                }
            });
            return a;
        },[]);

        dispatch({type:RESULTS_EVENTS,payload:sortedEvents});
        callback({status:1});
    }else{
        callback({status:2,msg:response.data.msg})
    }


};

export const setPublishedEvent =(eventId,callback)=>async(dispatch)=>{

    const response = await axios.post('https://us-central1-dance-suite.cloudfunctions.net/eventResult',{eventId});

    if(response.data.status ===1){
        dispatch({type:LOAD_EVENT_RESULTS,payload:response.data.data});
        callback({status:1});
    }else{
        callback({status:2,msg:response.data.msg});
    }


};

export const GetTerms =(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('terms');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content}=doc.data();
                dispatch({type:LOAD_TERMS,payload:content});
                callback({status:1});

            }else{
                callback({status:2,msg:'Unable to get terms'})
            }
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};
export const GetPrivacy=(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('privacy');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content}=doc.data();
                dispatch({type:LOAD_PRIVACY,payload:content});
                callback({status:1});

            }else{
                callback({status:2,msg:'Unable to get terms'})
            }
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};