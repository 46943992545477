import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {MultiDanceSection} from "./Alogrithms/finalAlgo";

class ViewMultiSectionMarks extends React.Component{


    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        return (
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>

                <GridItem xs={12}>
                    <h5>{title}</h5>
                </GridItem>
                <GridItem xs={12}>
                    {MultiDanceSection(this.props.sectionData)}
                </GridItem>
            </GridContainer>
        )
    }
}export default ViewMultiSectionMarks;