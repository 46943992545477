import React from "react";
import {connect}from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import {getPublishedEvents,setPublishedEvent} from "../Store/Actions";
//core components
import GridContainer from "../components/Grid/GridContainer";
import landingPageStyle from "../assets/jss/material-kit-react/views/landingPage";
import Parallax from "../components/Parallax/Parallax";
import CenterHeader from "../components/Header/CenterHeader";
import WhiteFooter from "../components/Footer/WhiteFooter";
import LinearLoading from "../components/Loading/LinearLoading";
import Danger from "../components/Typography/Danger";
import ClassNames from "classnames";
import ResultsEventsTable from "../components/Table/ResultsEventsTable";

class Home extends React.Component{

    state={
        loading:true,
        loadingErr:''
    };

    componentDidMount=()=>{
        this.props.getPublishedEvents(res=>{
            res.status ===1?
                this.setState({loading:false, loadingErr:''}):
                this.setState({loading:false,loadingErr:res.msg})

        })
    };

    renderEvents=()=>{
        const events = this.props.events;
        if(events!== null && events.length>0){

            return (
                <ResultsEventsTable
                    events={events}
                    link={'/event'}
                />
            )

        }else{
            if(this.props.loaded){
                return (
                    <div>
                        <h5>There are no results at this current moment.</h5>
                    </div>
                )
            }
        }

    };

    render(){
        const { classes, ...rest } = this.props;
        const {loading}=this.state;
        return (
            <div>
                <CenterHeader/>
                <Parallax small filter image={require("../assets/img/backgrounds/Results-bg.jpeg")}>
                    <div className={classes.container}>
                        <GridContainer alignItems={'center'} justify={'center'} direction={'column'}>
                            <h1><b>Results</b></h1>
                            <p style={{textAlign:'center',fontSize:18}}>All events that have made use of the Dance Suite system will have their results displayed once the officials have approved results.</p>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classes.section}>
                    {/*Event Section*/}
                    <div className={ClassNames(classes.main, classes.mainRaised)}>
                        <div style={{padding:10}}>
                            {loading? <LinearLoading show={true}/>:null}
                            {this.props.loaded && this.state.loadingErr!==''?
                                <Danger>{this.state.loadingErr}</Danger>:null
                            }
                            {this.renderEvents()}
                        </div>

                    </div>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return   {
        events:state.resultsEvents.events,
        loaded:state.resultsEvents.loaded
    }
};


export default connect(mapStateToProps,{getPublishedEvents,setPublishedEvent})(withStyles(landingPageStyle)(Home));