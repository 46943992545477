import React from "react";
import CookieConsent from "react-cookie-consent";
import colors from "../../Variables";

const ConsentCookie=()=>{
    const {container,button,anchor}=Styles;
    return (
        <CookieConsent
            location={'bottom'}
            buttonText={'I Understand'}
            cookieName={'ds-cookieConsent'}
            expires={150}

            style={container}
            buttonStyle={button}
        >
            <p>We use cookies and other technologies to tailor your experience. Learn more by reading our <span><a style={anchor} href={'/privacy'}>Disclaimer</a></span>. By clicking "Accept" or by continuing to use the site, you agree to the use of cookies and data.</p>
        </CookieConsent>
    )
}

const Styles={
    container:{
        background:'white',
        color:colors.dSBlue,
        borderTopWidth:1,
        borderTopStyle:'solid',
        borderTopColor:colors.dSGold
    },
    button:{
        background:colors.dSBlue,
        color:'white',
        borderRadius:50
    },
    anchor:{
        color:colors.dSGold
    }
}



export default ConsentCookie;
