import {RESULTS_EVENTS} from "../types";

const INITIAL_STATE={
    events:null,
    loaded:false
};

export default (state=INITIAL_STATE,action)=>{
    if(action.type===RESULTS_EVENTS){
        return {events:action.payload,loaded:true};
    }else{
        return {...state};
    }
};