import React from 'react';

export const specialDanceTable=(marks)=>{

    const firstAdj= marks[Object.keys(marks)[0]];
    const Dances = Object.keys(firstAdj);
    const Adjs = Object.keys(marks);
    const sortedMarks = SortedMarks(marks);
    const Cats = ["T","C","I","S","total"];
    const dancers = Object.keys(firstAdj[Dances[0]]['T']);

    const grandTotal={};



    function workOut(dancer){
        const totals =parseInt(Adjs.length*40);
        const dancerTotal = parseInt(grandTotal[dancer]);
        const percent = dancerTotal/totals*100;
        return parseFloat(percent).toFixed(2);
    }

    return (
        <div>
            {Dances.map(dance=>{
                return (
                    <div>
                        <h4>{dance.toUpperCase()}</h4>
                        <style>{`
                                                       
                            th{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                             fontSize:10
                            }
                            td{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                              fontSize:10
                            }
                            table{
                                border-collapse :collapse
                            }
                            
                          `}
                        </style>
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                {Adjs.map((adj,i)=>(
                                    <th key={adj+i} colSpan={5} style={{textAlign:'center'}}>{adj}</th>
                                ))}
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>#</th>
                                {Adjs.map((adj,i)=>{
                                   return Cats.map(cat=>(
                                        <th key={adj+cat}>{cat}</th>
                                    ))
                                })}
                                <th>Grand Total</th>
                                <th>%</th>
                            </tr>

                            </thead>
                            <tbody>
                            {dancers.map((dancer,i)=>{
                                return (
                                    <tr key={dancer+i+1}>
                                        <td>{dancer}</td>
                                        {Adjs.map(adj=>{
                                           return Cats.map(cat=>{
                                                if(cat==='total'){
                                                    if(grandTotal[dancer]){
                                                        grandTotal[dancer]+= sortedMarks[dance][adj][dancer][cat]
                                                    }else{
                                                        grandTotal[dancer]= sortedMarks[dance][adj][dancer][cat]
                                                    }

                                                }
                                                return (
                                                    <td>{sortedMarks[dance][adj][dancer][cat]}</td>
                                                )

                                            })

                                        })}
                                        <td>{grandTotal[dancer].toFixed(2)}</td>
                                        <td>{workOut(dancer)}%</td>
                                    </tr>
                                )


                            })}
                            </tbody>
                        </table>
                    </div>

                )
            })}
        </div>


    )

};

function SortedMarks(marks){

    const firstAdj= marks[Object.keys(marks)[0]];
    const Dances = Object.keys(firstAdj);
    const Adjs = Object.keys(marks);

    const dancers = Object.keys(firstAdj[Dances[0]]['T']);
    const Cats = ["T","C","I","S","total"];


    return Dances.reduce((a,v)=>{

        Adjs.map(adj=>{

            const AdjMarks=dancers.reduce((ar,dancer)=>{
                Cats.map(cat=>{
                    if(!ar[dancer]){
                        ar[dancer]={[cat]:marks[adj][v][cat][dancer]}
                    }else{
                        ar[dancer][cat]=marks[adj][v][cat][dancer]
                    }
                });

                return ar
            },{});

            if(a[v]){
                a[v][adj]=AdjMarks
            }else{
                a[v]={[adj]:AdjMarks}
            }

        });


        return a
    },{})
}



