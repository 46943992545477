import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

const rowStyle={padding:10, fontSize:15};

class VIewMultiSectionResults extends React.Component{

    renderResult=()=>{
            const {finalResult,dancers}=this.props.sectionData;
        const finalists = Object.keys(finalResult);
        const orderPlacings = Object.values(finalResult).sort((a,b)=>a-b);
        const dancerDetails = Object.keys(dancers).reduce((a,v)=>{

            const {names, number,studio}=dancers[v];
            a[number]={names,studio};

            return a;
        },{});


        return (
            <table>
                <tbody>
                {orderPlacings.map(place=>{
                    return finalists.map(dan=>{
                        if(finalResult[dan]===place){
                            return (
                                <tr key={place+''+dan}>
                                    <td style={rowStyle} >{place}</td>
                                    <td style={rowStyle}><b>{dancerDetails[dan].names}</b></td>
                                    <td style={rowStyle}>{dancerDetails[dan].studio}</td>
                                </tr>
                            )
                        }
                    })
                })}
                </tbody>
            </table>
        )
    };

    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,logo}=this.props.eventInfo;
        return(
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>

                <GridItem xs={12}>
                    <h5>{title}</h5>
                </GridItem>
                <GridItem xs={12}>
                    {this.renderResult()}
                </GridItem>

            </GridContainer>
        )
    }

}export default VIewMultiSectionResults;