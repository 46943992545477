import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import {TextField} from "@material-ui/core";

export const renderPanel=(panelSent,adjudicators)=>{
    const {panel,letters}=panelSent;
    const letterArr=letters.split(',');

    return (
        <GridContainer>
            <GridItem xs={12}>
                <h6>Panel-{panel}</h6>
                <GridItem xs={12} container direction={'column'}>
                    {letterArr.map(letter=>{
                        return (
                            <p key={letter}>{letter}-{adjudicators[letter]}</p>
                        )
                    })}
                </GridItem>
            </GridItem>
        </GridContainer>
    )
};


export const RenderInputs=({input, label, type, placeholder, name, meta})=>{
    let errorStatus = false;
    meta.error ? errorStatus=true:errorStatus=false;


    if(type!=='message'){

        return (
            <CustomInput
                labelText={label}
                id={name}
                formControlProps={{fullWidth:true}}
                inputProps={{...input,type:type}}
                placeholder={placeholder}
                error={errorStatus}
            />
        )
    }

};

export const RenderMessage=({input, label, type, placeholder, name, meta,classes})=>{
    let errorStatus = false;
    meta.error && meta.touched ? errorStatus=true:errorStatus=false;

    return (
        <TextField
            id={name}
            label={label}
            multiline
            rowsMax='4'
            //defaultValue={placeholder}
            margin={'normal'}
            error={errorStatus}
            fullWidth
            {...input}
        />
    )
};