import React from "react";
import {Router,Route}from 'react-router-dom';
import history from "./history";

//components
import './assets/css/material-dashboard-react.css';
import Home from "./Screens/Home";
import ResultsEvent from "./Screens/Event";
import ViewResult from "./Screens/ViewResult";
import ConsentCookie from "./components/ConsentCookie";
import Privacy from "./Screens/Privacy";
import Terms from "./Screens/Terms";
const App =()=>{
    return (
        <div>
            <ConsentCookie/>
            <Router history={history}>
                <Route exact path={'/'} component={Home}/>{/*Results*/}
                <Route exact path={'/event/:id'} component={ResultsEvent}/>{/*Results/events*/}
                <Route exact path={'/event/section/:id'} component={ViewResult}/>{/*Results/events/section/*/}
                <Route exact path={'/privacy'} component={Privacy}/>
                <Route exact path={'/terms'} component={Terms}/>
            </Router>
        </div>
    )
}
export default App;