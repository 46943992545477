import React from "react";
import {dancesWon, returnFinalResult} from "./Alogrithms/finalAlgo";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {renderPanel} from "./Functions/";

const rowStyle={padding:10, fontSize:15};
class ViewFinalMarksResult extends React.Component{

    renderPositions=()=>{

        const {rounds,dancers,type}=this.props.sectionData;
        const {results}=this.props.roundData;
        const finalPlacingResult = type !=='multiDance'?returnFinalResult(rounds):[results];

        const organizedDancers = Object.keys(dancers).reduce((a,v)=>{
            const dancer = dancers[v].number;
            a[dancer]= {
                name:dancers[v].names,
                heat : this.props.sectionType ==='social'? dancers[v].heat!== undefined?dancers[v].heat:0:0,
                teacher:this.props.sectionType==='social'? dancer[v].teacher!== undefined?dancer[v].teacher:'':''
            };

            return a;
        },{});

        const dancesResult=dancesWon(this.props.roundData);


        return (
            <div>
                {finalPlacingResult.map((round,i)=>{

                    const sortedPlacings = Object.values(round).sort((a,b)=>a-b);
                    const sortedDancers = Object.keys(round);
                    const distinctPLaces = [...new Set(sortedPlacings)];
                    return (
                        <div>
                            <table>

                                <tbody>
                                {distinctPLaces.map(place=>{
                                    return sortedDancers.map((dancer,i)=>{
                                        if(round[dancer]=== place){
                                            return(
                                                <tr key={dancer}>
                                                    <td style={rowStyle}>{place}</td>
                                                    <td style={rowStyle}><b>{dancer}</b></td>
                                                    <td style={rowStyle}>{organizedDancers[dancer].name}</td>
                                                    {type ==='social'?
                                                        <td>{organizedDancers[dancer].heat}</td>:null
                                                    }
                                                    {type ==='social'?
                                                        <td>{organizedDancers[dancer].teacher}</td>:null
                                                    }
                                                    {dancesResult[dancer] !== undefined?
                                                        <td>{dancesResult[dancer].join(', ')}</td>:null
                                                    }

                                                </tr>
                                            )
                                        }
                                    })

                                })}
                                </tbody>
                            </table>
                            <br/>
                        </div>
                    )
                })}
            </div>
        ) ;
    };

    renderUncontested=()=>{
        const {results, activeList, roundType}=this.props.roundData;
        const {dancers,type}=this.props.sectionData;
        const dancersList = Object.keys(dancers).reduce((a,v)=>{

            const dancerInfo = dancers[v];

            a[dancerInfo.number]={
                name: dancerInfo.names,
                heat : type ==='social'?dancerInfo.heat!== undefined?dancerInfo.heat:null:null,
                teacher:type==='social'?dancerInfo.teacher!== undefined?dancerInfo.teacher:null:null
            };

            return a;
        },{});



        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Number</th>
                        <th>Result</th>
                        <th>Name</th>
                        {type ==='social'?
                            <th>Heat</th>:null
                        }
                        {type ==='social'?
                            <th>Teacher</th>:null
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {activeList.sort((a,b)=>a-b).map(dancer=>{
                        return (
                            <tr key={dancer}>
                                <td style={rowStyle}><b>{dancer}</b></td>
                                <td style={rowStyle}>{results[dancer]}</td>
                                <td style={rowStyle}>{dancersList[dancer].name}</td>
                                {type ==='social'?
                                    <td>{dancersList[dancer].heat}</td>:''
                                }
                                {type ==='social'?
                                    <td>{dancersList[dancer].teacher}</td>:''
                                }

                            </tr>
                        )


                    })}
                    </tbody>
                </table>
            </div>
        )

    };

    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        const {type,}=this.props.sectionData;
        const {dances,roundType,itemNo,panel,roundNo}=this.props.roundData;
        return (
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>

                <GridItem xs={12}>
                    <h5>{type==='multiDance'?itemNo+' '+title+'-'+roundType+'-'+dances[0]:itemNo+' '+title+'-'+roundType}           Round No:{roundNo}</h5>

                </GridItem>
                <GridItem xs={12}>
                    {renderPanel(panel,adjudicators)}
                </GridItem>
                <GridItem xs={12}>
                    {roundType ==='Final'?
                        this.renderPositions():
                        this.renderUncontested()
                    }
                </GridItem>
            </GridContainer>
        )
    }
}export default ViewFinalMarksResult;