import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

class ViewRoundResult extends React.Component{

    renderDancers=(results,dancers)=>{

        return (
            <table>
                <tbody>
                {results.map(dancer=>{
                   return Object.keys(dancers).map(d=>{
                        const {number,names}=dancers[d];
                        if(number ===dancer){
                            return (
                                <tr key={d}>
                                    <td>{dancer}</td>
                                    <td>{names}</td>
                                </tr>
                            )
                        }
                    })
                })}
                </tbody>
            </table>
        )


    };

    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        const {dances,roundType,itemNo,panel,roundNo,results}=this.props.roundData;
        const {dancers,type}=this.props.sectionData;
        return (
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>

                <GridItem xs={12}>
                    <h5>{type==='multiDance'?itemNo+' '+title+'-'+roundType+'-'+dances[0]:itemNo+' '+title+'-'+roundType}           Round No:{roundNo}</h5>
                </GridItem>
                <GridItem xs={12}>
                    <h6>{results.length} Dancers Called Back</h6>
                    {this.renderDancers(results,dancers)}
                </GridItem>

            </GridContainer>
        )
    }

}
export default ViewRoundResult;