import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {renderPanel} from "./Functions"
import {marksTable} from "./Alogrithms/roundAlgo";


class ViewRoundMarks extends React.Component{


    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        const {roundType,itemNo,panel,marks,roundNo,results}=this.props.roundData;
        return(
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>
                <GridItem xs={12}>
                    <h5>{itemNo+' '+title+'-'+roundType}           Round No:{roundNo}</h5>
                </GridItem>
                <GridItem xs={12}>
                    {renderPanel(panel,adjudicators)}
                </GridItem>
                <GridItem xs={12}>
                    {marksTable(this.props.roundData,marks)}
                </GridItem>
                <GridItem xs={12}>
                    <h4>Dancers Called back: <b>{results.join(',  ')}</b></h4>
                </GridItem>
            </GridContainer>
        )
    }
}export default ViewRoundMarks;