import {LOAD_EVENT_RESULTS} from "../types";

const INITIAL_STATE={
    eventData:null,
    loaded:false
};

export default (state=INITIAL_STATE,action)=>{
    if(action.type===LOAD_EVENT_RESULTS){
        return {
            eventData:action.payload,
            loaded: true
        }
    }else{
        return {...state};
    }
}