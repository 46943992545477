import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {FooterStyles, SiteLink, TitleLink} from "./FooterFunctions";
import {DSDivider} from "../Divider";

const Logo = require('../../assets/img/Main_Logo.png');

const WhiteFooter=()=>{
    return (
        <div>
            <DSDivider/>
            <footer style={FooterStyles.whiteContainer}>
                <GridContainer justify={'center'}>
                    <GridItem style={FooterStyles.imageGrid} sm={12} md={4} container justify={'center'} alignItems={'center'}>
                        {<img src={Logo} alt={'logo'} style={FooterStyles.image}/>}
                    </GridItem>
                    <GridItem sm={12} md={8}>
                        <GridContainer style={FooterStyles.linksContainer}>
                            <GridItem xs={6} sm={6} md={3}>
                                <ul>
                                    <li><TitleLink name={'Events'}/></li>
                                    <li><SiteLink color={'blue'} name={'Results'} type={'link'} link={'https://results.dance-suite.com'}/></li>
                                    <li><SiteLink color={'blue'} name={'Live'} type={'link'} link={'https://live.dance-suite.com'}/></li>
                                </ul>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                                <ul>
                                    <li><TitleLink name={'Main'}/></li>
                                    <li><SiteLink color={'blue'} name={'Home'} type={'link'} link={'https://dance-suite.com'}/></li>
                                    <li><SiteLink color={'blue'} name={'About'} type={'link'} link={'https://dance-suite.com/about'}/></li>
                                </ul>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                                <ul>
                                    <li><TitleLink name={'Users'}/></li>
                                    <li><SiteLink color={'blue'} name={'Dancers'} type={'link'} link={'https://dance-suite.com/dancers'}/></li>
                                    <li><SiteLink color={'blue'} name={'Organizers'} type={'link'} link={'https://dance-suite.com/organizers'}/></li>
                                    <li><SiteLink color={'blue'} name={'Officials'} type={'link'} link={'https://offificals.dance-suite.com'}/></li>
                                </ul>

                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                                <ul>
                                    <li><TitleLink name={'Connect'}/></li>
                                    <li><SiteLink color={'blue'} name={'Contact Us'} type={'link'} link={'https://dance-suite.com/contact'}/></li>
                                    <li><a style={FooterStyles.siteLinkWhite} href={'mailto:djmwinter@gmail.com'}>Email</a></li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </footer>
            <footer style={FooterStyles.goldContainer}>
                <GridContainer alignItems={'center'}>
                    <GridItem xs={12} sm={12} md={6} container alignItems={'center'} >
                        <span style={FooterStyles.siteLinkWhite}> &copy; {1900 + new Date().getYear()} dance-suite.com</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} container direction={'row'} alignItems={'center'} justify={'flex-end'}>
                        <SiteLink name={'Terms & Conditions'} link={'/terms'}/>
                        <SiteLink name={'Privacy'} link={'/privacy'}/>
                    </GridItem>
                </GridContainer>
            </footer>
        </div>
    )
}

export default WhiteFooter;
