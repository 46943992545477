import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {marksTableFinal} from "./Alogrithms/finalAlgo";
import {unContestedTable, UncontestFinalSpecialTable} from "./Alogrithms/uncontestedAlgo";
import {specialDanceTable} from "./Alogrithms/specialFunctions";
import {renderPanel} from "./Functions";

class ViewFinalMarks extends React.Component{


    renderMarks=(roundData,roundType)=>{

            if(roundType==='Final'){
                return (marksTableFinal(roundData))
            }else{
                const dances = roundData.dances;
                if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                    return UncontestFinalSpecialTable(roundData);
                }else{
                    return unContestedTable(roundData)
                }
            }
    };

    renderSpecialMarks=(marks)=>{
        if(marks !== undefined){
            return specialDanceTable(marks)
        }
    };

    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        const {type,}=this.props.sectionData;
        const {dances,roundType,itemNo,panel,specialMarks,roundNo}=this.props.roundData;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                        <img src={logo.url} alt={'logo'} height={100}/>
                    </GridItem>
                    <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                        Title: {title}<br/>
                        Date:{date}<br/>
                        Venue:{venue}<br/>
                        Organization:{organization}<br/>
                        Organizer:{organizer.name}<br/>
                        Chairperson:{chairperson}<br/>
                        Scrutineer:{scrutineer.name}
                    </GridItem>

                    <GridItem xs={12}>
                        <h5>{type==='multiDance'?itemNo+' '+title+'-'+roundType+'-'+dances[0]:itemNo+' '+title+'-'+roundType}           Round No:{roundNo}</h5>

                    </GridItem>
                    <GridItem xs={12}>
                        {renderPanel(panel,adjudicators)}
                    </GridItem>
                    <GridItem xs={12}>
                        {this.renderMarks(this.props.roundData, roundType)}
                    </GridItem>
                    <GridItem xs={12}>
                        {
                            dances.includes('exhibition') ||
                            dances.includes('showcase')||
                            dances.includes('showdance')||
                            dances.includes('solo spot') ?
                                <GridItem xs={12}>
                                    <h3>TCIS Marks</h3>
                                    <p>T-Technique C-Choreography I-Image S-Show </p>
                                    {this.renderSpecialMarks(specialMarks)}
                                </GridItem>:null
                        }

                    </GridItem>


                </GridContainer>
            </div>
        )
    }
}
export default ViewFinalMarks;