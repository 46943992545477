/****FUNCTIONS**/
// function to count the number of placings compared to the place and higher(lower value)
const CountFunction=(dancerArray,place)=>{

    return dancerArray.reduce((a,v)=>{

        if(parseInt(v)<=parseInt(place)){
            a+=1
        }

        return a;
    },0)

};
// function to add up the placings compared to the place and higher(lower value)
const AddUpPlace =(dancerArray,place)=>{

    return dancerArray.reduce((a,v)=>{

        if(parseInt(v)<=parseInt(place)){
            a+=parseInt(v);

        }
        return a;
    },0)

};
// function to work out what the required majority is;
const MajorityFunction=(marks)=>{

    const noOfAdjudicators = marks.length;

    const Maj = Math.ceil(noOfAdjudicators/2);
    if(!!(noOfAdjudicators&1)){
        return Maj
    }else{

        return Maj+1
    }



};
// function to calculate avaliblePlacings
const AvailablePlacings =(dancers)=>{
    return dancers.reduce((a,v,i)=>{

        a.push(i+1)

        return a;
    },[])
}
// function to calculate availablePlacings from start place
const AvailablePlacingsFromStart=(dancers, startMark)=>{
    //console.log(dancers)
    return dancers.reduce((a,v,i)=>{
        a.push(startMark+i)

        return a
    },[])
}
// function to calculate differenct of positions for table data
const TablePositionDiffernce=(startNum)=>{
    let diff=0;
    let start =startNum;
    while(start!==0){
        diff+=1;
        start-=1
    };
    return diff
}

export const DanceMarksWithTable=(marks,startMark)=>{

    let Dancers = Object.keys(marks);
    const Majority = MajorityFunction(marks[Dancers[0]]);
    const Result ={};
    const TempTableData=[];
    const TableData=[];
    let availablePlacings = startMark===undefined?AvailablePlacings(Dancers):AvailablePlacingsFromStart(Dancers,startMark);
    let AssessPlace = startMark===undefined?1:startMark;;
    let tempArray ={};
    let tempPlace =startMark===undefined?1:startMark;
    let nextTempArray={};
    //console.log(startMark);
    const diffNum = startMark!==undefined?TablePositionDiffernce(startMark):0;
    //console.log(diffNum)


    const ResetFunction=()=>{
        if(availablePlacings.length>0){
            tempArray={};
            nextTempArray={};
            AssessPlace=1;
            DancersCount(AssessPlace);
        }
    }

    const HandleTableData=(position,dancer,mark)=>{
        if(startMark===undefined || startMark===1){
            if(TableData[position-1]!== undefined){
                if(dancer !== undefined){
                    TableData[position-1].push({[dancer]:mark})
                }

            }else{
                if(dancer !== undefined){
                    TableData.push([{[dancer]:mark}])
                }else{
                    TableData.push([])
                }

            }
        }else{
            if(position>=startMark){
                //console.log(position+'-'+dancer+'-'+mark);
                //console.log(position-diffNum)
                if(TableData[position-diffNum]!==undefined){
                    if(dancer!==undefined){
                        TableData[position-diffNum].push({[dancer]:mark})
                    }
                }else{
                    if(dancer!==undefined){
                        TableData.push([{[dancer]:mark}])
                    }else{
                        TableData.push([])
                    }
                }
            }


        }



    }

    const PlaceDancer=(dancer,place)=>{
        //console.log(dancer+'-'+place)
        if(Result[dancer]===undefined){
            Result[dancer]=place;
            availablePlacings=availablePlacings.filter(p=>p!==place?p:null);
        }

        //Dancers = Dancers.filter(d=>d!==dancer?d:null);
        return true
    }

    const PlaceDancersWithTie=(dancers,places)=>{

        const dancersAmount = dancers.length;
        const placesSum = places.reduce((a,v)=>{

            a+= v;
            return a;
        },0)

        const place = placesSum/dancersAmount;
        dancers.map((dancer,i)=>{
            Result[dancer]=place;
            availablePlacings=availablePlacings.filter(p=>p!==places[i]?p:null)
        })

        return true;
    }

    const HandleNoDancers=(currentPlace)=>{

        //console.log(currentPlace)
        DancersCount(currentPlace);
    }

    const DancersCount=(place)=>{
        let DancerCount =0;
        Dancers.map(dancer=>{
            if(Result[dancer]=== undefined){

                const count = CountFunction(marks[dancer],place);
                if(count>=Majority){
                    tempArray[dancer]=count;
                }

            }
            DancerCount+=1

        });
        if(DancerCount===Dancers.length){
            AssessTempArray(tempArray)
        }
    }

    const HandleMultipleArray=(testArray)=>{
        const array = testArray;
        // first step is to assess how many majorities there are
        //console.log(array)
        const Majorities = [...new Set(Object.values(array))]

        if(Majorities.length === Object.keys(array).length){
            // all have different majorities can therefor place
            Majorities.sort((a,b)=>b-a).map(maj=>{

                Object.keys(array).map(dancer=>{
                    if(array[dancer]===maj){
                        HandleTableData(AssessPlace,dancer,maj)
                        PlaceDancer(dancer,availablePlacings[0])
                    }
                });

            })
            ResetFunction();
        }// dancers have different majorities or some dancers have the same
        else{


            // first access how many dancers are linked to each majority
            const SameMajority = Majorities.sort((a,b)=>b-a).reduce((a,v)=>{

                Object.keys(array).map(dancer=>{
                    if(array[dancer]===v){
                        if(!a[v]){
                            a[v]=[dancer]
                        }else{
                            a[v].push(dancer)
                        }
                    }
                })

                return a;
            },{})



            // loop through all those majorities and see how many are linked to each one
            Object.keys(SameMajority).sort((a,b)=>b-a).map((key,index)=>{

                // only one dancer linked to that majority
                if(SameMajority[key].length ===1){
                    const dancer = SameMajority[key][0];

                    PlaceDancer(dancer,availablePlacings[0]);
                    HandleTableData(AssessPlace,dancer,array[dancer])
                }// many dancers linked to that majority
                else{

                    // first step here is to add the places of assessPlace an higher
                    const addedUpPlaces = SameMajority[key].reduce((a,v)=>{

                        a[v]=AddUpPlace(marks[v],AssessPlace)

                        return a;
                    },{})

                    //console.log(addedUpPlaces)
                    // now create a new distinct values of added values
                    const AddedUpPlacesCount = [...new Set(Object.values(addedUpPlaces))]

                    // now check to see if adding up places has split the tie
                    if(AddedUpPlacesCount.length === Object.keys(SameMajority[key]).length){
                        // if adding up has split the tie then we can place those dancers from lowest total to highest

                        AddedUpPlacesCount.sort((a,b)=>a-b).map(total=>{

                            Object.keys(addedUpPlaces).map(dancer=>{

                                if(addedUpPlaces[dancer]===total){
                                    PlaceDancer(dancer,availablePlacings[0]);
                                    HandleTableData(AssessPlace,dancer,array[dancer]+'('+addedUpPlaces[dancer]+')')
                                }
                            });
                        })
                        ResetFunction();
                    }else{

                        const secondCheck = AddedUpPlacesCount.reduce((a,v)=>{

                            SameMajority[key].map(dancer=>{
                                if(addedUpPlaces[dancer]===v){
                                    if(!a[v]){
                                        a[v]=[dancer]
                                    }else{
                                        a[v].push(dancer)
                                    }
                                }
                            })
                            return a;
                        },{});




                        Object.keys(secondCheck).sort((a,b)=>a-b).map((addVal,i)=>{

                            if(secondCheck[addVal].length===1){
                                // only one dancer to that added total, can then place
                                const dancer = secondCheck[addVal][0];
                                if(i===0){
                                    // first dancer in the array wins the place
                                    PlaceDancer(dancer,availablePlacings[0]);
                                    HandleTableData(AssessPlace,dancer,array[dancer]+'('+addedUpPlaces[dancer]+')')
                                }else{
                                    const dancersCompeting = SameMajority[key].length;
                                    const place = availablePlacings[dancersCompeting-i];

                                    HandleTableData(AssessPlace,dancer,array[dancer]+'('+addedUpPlaces[dancer]+')')
                                    PlaceDancer(dancer,place)
                                }
                            }// many dancers to that one added value, therefore we need to move to the next place
                            else{


                                // add table data for these places
                                secondCheck[addVal].map(dancer=>{
                                    HandleTableData(AssessPlace,dancer,array[dancer]+'('+addedUpPlaces[dancer]+')')
                                })


                                const nextPlace = AssessPlace+1;


                                if(nextPlace > Dancers.length){
                                    // we need to tie the dancers

                                    const dancers = secondCheck[addVal];
                                    const places = dancers.reduce((a,v,i)=>{

                                        a.push(availablePlacings[i])

                                        return a;
                                    },[]);
                                    PlaceDancersWithTie(dancers,places);

                                    if(Object.keys(Result).length!== Dancers.length){
                                        ResetFunction()
                                    }
                                }else{
                                    // we then need to move on to the next place for the dancers competiting


                                    let dancerCount =0
                                    nextTempArray={}
                                    secondCheck[addVal].map(dancer=>{
                                        const count = CountFunction(marks[dancer],nextPlace);
                                        if(count>=Majority){
                                            nextTempArray[dancer]=count
                                        };
                                        dancerCount+=1
                                    });
                                }
                            }
                            if(Object.keys(Result).length !== Dancers.length){
                                if(i+1 === Object.keys(secondCheck).length){

                                    if(Object.keys(nextTempArray).length>0){
                                        AssessPlace+=1;
                                        AssessTempArray(nextTempArray)
                                    }else{
                                        ResetFunction();
                                    }
                                }
                            }


                        })

                    }

                }



            })

            // ResetFunction();

        }


    }


    const AssessTempArray=(array)=>{

        const TempArrayLength = Object.keys(array).length;

        if(TempArrayLength===1){

            //only one dancer in the temp array can place them with the next available array
            const dancer = Object.keys(array);

            const placedDancer = PlaceDancer(dancer[0],availablePlacings[0]);
            // add table data
            HandleTableData(AssessPlace,dancer[0],tempArray[dancer[0]])
            // reset function
            ResetFunction();
        }else if(TempArrayLength===0){

            // no dancers need to try the next place
            HandleTableData(AssessPlace)
            AssessPlace+=1
            HandleNoDancers(AssessPlace)

        }else{
            // multiple dancers in temp array, call function that handles that
            HandleMultipleArray(array);
        }



    }



    DancersCount(AssessPlace);
    //console.log(TableData);
    const SortedtableData = TableData.reduce((a,v,i)=>{

        const positionData = TableData[i];
        if(positionData.length>0){

            a[i+1]=positionData.reduce((ar,va,index)=>{
                const mark =	Object.entries(positionData[index])
                ar[mark[0][0]]=mark[0][1]
                return ar;
            },{})

        }else{
            a[i+1]={}
        }

        return a;
    },{});

    //console.log(SortedtableData)
    //console.log('result')
    return {results:Result,tableData:SortedtableData}
};

/*******************final Sumary function */
export const FinalSummary=(section)=>{

    const dances= Object.keys(section);
    const dancers = Object.keys(section[dances[0]]);
    const FinalResult ={};
    const Rule11TableData={};
    const RuleTableData={};

    let finalAvailablePlacings = AvailablePlacings(dancers)
    let assessPlace = 1;

    // reset assess place;
    const resetAssessPlace=()=>{
        if(finalAvailablePlacings[0]!== undefined){
            const newAssessPlace = finalAvailablePlacings[0];
            assessPlace=newAssessPlace;
        }else{
            assessPlace=0
        }
    }

    // get results from each dance
    const DancesResults = dances.reduce((a,v)=>{


        a[v]=DanceMarksWithTable(section[v]).results

        return a;
    },{})

    // create a final sum array for each dance of each dance result
    const FinalSumArr= dancers.reduce((a,v)=>{

        dances.map(dance=>{
            const mark = DancesResults[dance][v]
            if(!a[v]){
                a[v]=[mark]
            }else{
                a[v].push(mark)
            }

        })

        return a;
    },{});

    // create a total sum for each dancer
    const TotalSumArr = dancers.reduce((a,v)=>{

        a[v]=FinalSumArr[v].reduce((ar,val)=>{
            ar+=val
            return ar
        },0)


        return a;
    },{});

    // place dancer function
    const PlaceDanerFinal=(dancer)=>{
        const place = finalAvailablePlacings[0];
        FinalResult[dancer]=place;
        finalAvailablePlacings = finalAvailablePlacings.filter(d=>place!==d?d:null);
        resetAssessPlace()

    };

    // place dancers tie function
    const PlaceDancersFinalTie=(dancers)=>{

        const allPlaces=dancers.map(d=>{
            const place = finalAvailablePlacings[0];
            finalAvailablePlacings=finalAvailablePlacings.filter(p=>p!==place?p:null);
            return place;
        })

        //console.log('all places tie',allPlaces)


        const placeSum = allPlaces.reduce((a,v)=>{
            a+=v;
            return a;
        },0);

        const place = placeSum/dancers.length;



        dancers.map(dancer=>{
            FinalResult[dancer]=place;
        });
        resetAssessPlace();

    }

    //add dancer to rule table data
    const AddRuleData=(dancer)=>{
        if(!Object.keys(RuleTableData).includes(dancer)){
            RuleTableData[dancer]='Rule 10'
        }
    }

    // rule 10 count function
    const Rule10CountFunction=(array)=>{
        return array.reduce((a,v)=>{
            a[v]=CountFunction(FinalSumArr[v],assessPlace)
            return a;
        },{})
    }

    // rule 10 function
    const Rule10Function=(array)=>{
        const newRule10Arry = Rule10CountFunction(array);
        const rule10DistinctValues = [... new Set(Object.values(newRule10Arry))];
        const placeWinner =[];
        let mode='normal';

        //console.log('new rule 10 count',newRule10Arry);


        if(rule10DistinctValues.length=== array.length){
            // counting how many places each dancer has split the tie we can return for placing
            //console.log(rule10DistinctValues)
            const distinctVal = rule10DistinctValues.sort((a,b)=>b-a)[0];

            array.map(dancer=>{
                if(newRule10Arry[dancer]===distinctVal){
                    placeWinner.push(dancer)
                }
            });

        }
        else{

            // first we need to see how many dancers are linked to each count
            const newCount = rule10DistinctValues.reduce((a,v)=>{

                array.map(dancer=>{
                    if(newRule10Arry[dancer]===v){
                        if(!a[v]){
                            a[v]=[dancer]
                        }else{
                            a[v].push(dancer)
                        }
                    }
                })

                return a;
            },{});


            // loop through the count and assess how many dancers are linked to that count
            Object.keys(newCount).sort((a,b)=>b-a).map((count,index)=>{
                if(newCount[count].length===1 && index ===0){
                    placeWinner.push(newCount[count][0])
                }else if(index ===0){

                    // next step is to add up the places
                    const r10AddedPlaces = newCount[count].reduce((a,v)=>{
                        a[v]=AddUpPlace(FinalSumArr[v],assessPlace)
                        return a;
                    },{});

                    // create a disinct values of the added up places
                    const r10AddedPlacesDistinctValues = [...new Set(Object.values(r10AddedPlaces))];
                    // now check if adding them has split the tie
                    if(r10AddedPlacesDistinctValues.length === newCount[count].length){

                        const firstAddedValue= r10AddedPlacesDistinctValues.sort((a,b)=>a-b)[0];

                        Object.keys(r10AddedPlaces).map(dancer=>{
                            if(r10AddedPlaces[dancer]===firstAddedValue &&index===0){
                                // if it is the first time the loop is running and we have split the tie
                                // we can return the winner
                                placeWinner.push(dancer);
                            }
                        })

                    }else{
                        // we move on to rule 11
                        const R11Result = Rule11Function(newCount[count]);


                        // if only two dancers have been sent to rule 11 then we can place those two dancers
                        if(newCount[count].length===2){
                            // need to determine if tie or not
                            if(R11Result.length===1){
                                //no tie
                                placeWinner.push(R11Result[0]);
                                newCount[count].map(dancer=>{
                                    if(dancer !== R11Result[0] ){
                                        placeWinner.push(dancer)

                                    }
                                    RuleTableData[dancer]='Rule 11'
                                });
                                mode='rule11';
                            }else{
                                // there is a tie

                                R11Result.map(dancer=>{
                                    placeWinner.push(dancer);
                                    RuleTableData[dancer]='Rule 11';
                                });
                                mode='rule11Tie'
                            }


                        }else if(R11Result.length===1){
                            placeWinner.push(R11Result[0]);
                            RuleTableData[R11Result[0]]='Rule 11'
                        }else{
                            // handle rule 11 tie
                            R11Result.map(dancer=>{
                                placeWinner.push(dancer);
                                RuleTableData[dancer]='Rule 11'
                            });
                            mode='rule11Tie'
                        }
                    }

                }
            })
        }

        if(placeWinner.length>0){
            //console.log('place winner',placeWinner)
            return {placeWinner:placeWinner, mode}
        }


    }

    // rule 11 function
    const Rule11Function=(array)=>{
        //console.log('rule 11 array');
        //console.log(array);

        const Rule11Section = array.reduce((a,v)=>{

            a[v]=dances.reduce((ar,val)=>{
                section[val][v].map(num=>{
                    ar.push(num)
                });
                return ar
            },[])
            return a;
        },{});
        let R11FinalPlace=[];
        const R11Result={};


        const R11DanceTable = DanceMarksWithTable(Rule11Section,assessPlace);
        //console.log(R11DanceTable)

        const R11DanceTableResult = R11DanceTable.results;
        const R11DanceTableTable = R11DanceTable.tableData;

        //console.log('table data for rule return',R11DanceTableTable)
        //console.log('result data from rule 11 retrun ', R11DanceTableResult)

        const DistinctValues = [...new Set(Object.values(R11DanceTableResult))];
        //console.log(DistinctValues);
        // we have a clear winner and can split them
        if(DistinctValues.length === array.length){

            const placeWinner = DistinctValues.sort((a,b)=>a-b)[0];

            const dancer = Object.keys(R11DanceTableResult).reduce((a,v)=>{
                if(R11DanceTableResult[v]===placeWinner){
                    a.push(v)
                }
                return a;
            },[])[0];

            //console.log(dancer)
            R11FinalPlace.push(dancer);
            R11Result[dancer]=finalAvailablePlacings[0];
            if(array.length===2){
                array.map(dan=>{
                    if(dancer!==dan){
                        R11Result[dan]=finalAvailablePlacings[1];
                    }
                })
            }

            /*

             Object.keys(R11DanceTableResult).map((dancer,ind)=>{
               if(R11DanceTableResult[dancer]===placeWinner){
                 R11FinalPlace.push(dancer);
               }
               const place = finalAvailablePlacings[ind];

               R11Result[dancer]=finalAvailablePlacings[ind];
             });
             */


            SortTableData();

        }// there is no winner therfor we have to place in a tie
        else{
            //first step is to identify if there is a clear winner then they win the place

            const R11TieResult = DistinctValues.reduce((a,v)=>{

                Object.keys(R11DanceTableResult).map(dancer=>{
                    if(R11DanceTableResult[dancer]===v){
                        if(!a[v]){
                            a[v]=[dancer]
                        }else{
                            a[v].push(dancer)
                        }
                    }
                })
                return a;
            },{});

            const firstPlaceResult =  Object.keys(R11TieResult).sort((a,b)=>a-b)[0];

            // we need to build a result for the dancers that have been tied with rule 11;
            const allPlaces = R11TieResult[firstPlaceResult].map((d,i)=>{
                return finalAvailablePlacings[i];
            })
            const placeSum = allPlaces.reduce((a,v)=>{
                a+=v
                return a;
            },0);
            const place = placeSum/R11TieResult[firstPlaceResult].length;
            R11TieResult[firstPlaceResult].map(d=>{
                R11Result[d]=place;
            })




            SortTableData();
            R11FinalPlace = R11TieResult[firstPlaceResult];



        }

        // sort out table data
        function SortTableData(){
            const tableKeys=Object.keys(R11DanceTableTable);
            const tableData = tableKeys.reduce((a,v)=>{

                a.push(Object.keys(R11DanceTableTable[v]).map(data=>{
                    return {[data]:R11DanceTableTable[v][data]}
                }))

                return a;
            },[]);

            tableData.push(R11Result)


            Rule11TableData[assessPlace]=tableData
        }



        return R11FinalPlace

    };

    // rule 10 assess function
    const Rule10AccessFunction=(array)=>{

        //console.log('assess r10 array',array)
        assessPlace = finalAvailablePlacings[0];

        if(array.length ===2){
            /*
              there are only 2 dancers competing for a place, we can split them with
              rule 10 and/or rule 11.
            */

            const r10Result = Rule10Function(array);
            const result = r10Result.placeWinner;
            const mode=r10Result.mode;




            if(result.length === 1&& mode==='normal'){
                // split the tie, we can then place
                const dancerWinner = result[0];

                PlaceDanerFinal(dancerWinner);
                AddRuleData(dancerWinner);


                const otherDancer = array.reduce((a,v)=>{
                    if(v !== dancerWinner){
                        a.push(v)
                    }
                    return a;
                },[])[0];

                if(otherDancer!==null){
                    PlaceDanerFinal(otherDancer);
                    AddRuleData(otherDancer);
                }



            }else if(mode==='rule11'){

                result.map(dancer=>{
                    PlaceDanerFinal(dancer)
                });

            }
            else{
                // tie for places
                //console.log(result)
                PlaceDancersFinalTie(result);
            }


        }else{
            /*
              there are more then 2 dancers, therfore we need to split them with rule10
              and/or rule 11, if we can split them then we need to put the rest back in the pot
              for the next place and go back to rule 10
             */
            const r10Result = Rule10Function(array)
            const result = r10Result.placeWinner
            const mode=r10Result.mode;

            //console.log('return result r10/11',result)


            if(result.length===1 && mode==='normal'){
                const dancer = result[0];
                PlaceDanerFinal(dancer);
                AddRuleData(dancer);

                assessPlace= finalAvailablePlacings[0];
                const newArray = array.filter(d=>dancer!==d?d:null);
                Rule10AccessFunction(newArray)
            }//if all dancers in the array are tied for the place
            else if(result.length === array.length && mode ==='normal'){
                // place dancers with tie
                PlaceDancersFinalTie(result);
                assessPlace=finalAvailablePlacings[0];
            }
            /* only some of the dancers are tied for the place then we need to place
               those we can and run rule 10 on the others
              */
            else{
                // only two dancers were sent to rule 11 and can therefore be placed from r11
                if(mode ==='rule11'){
                    result.map(dancer=>{
                        PlaceDanerFinal(dancer)
                    });

                    const newArray = array.reduce((a,v)=>{
                        if(!result.includes(v)){
                            a.push(v)
                        }
                        return a;
                    },[]);

                    if(newArray.length===1){
                        // only one dancer remaining therfore we can place that dancer
                        PlaceDanerFinal(newArray[0]);
                        AddRuleData(newArray[0])
                    }else{
                        Rule10AccessFunction(newArray)
                    }
                }else if(mode==='rule11Tie'){
                    // handle the tie from rule 11
                    if(result.length === array.length){
                        // all the dancers have been placed in a tie
                        PlaceDancersFinalTie(result);
                        assessPlace=finalAvailablePlacings[0];
                    }else{
                        // only some of the dancers were placed in a tie ther rest need to go back to rule 10
                        PlaceDancersFinalTie(result);

                        assessPlace=finalAvailablePlacings[0];
                        const anotherNewArray = array.reduce((a,v)=>{

                            if(!result.includes(v)){
                                a.push(v)
                            }
                            return a;
                        },[]);

                        Rule10AccessFunction(anotherNewArray);

                    }

                }
            }
        }
    }

    // create a distinct values test of the values of the total sum arr
    const DistinctTotals = [...new Set(Object.values(TotalSumArr))]

    // check Distinct totals against dancer, if the same length there are no ties and we can place each dancer
    if(DistinctTotals.length === dancers.length){
        // Distinct totals are the same, we can place the dancers
        DistinctTotals.sort((a,b)=>a-b).map(total=>{
            Object.keys(TotalSumArr).map(dancer=>{

                if(TotalSumArr[dancer]===total){
                    PlaceDanerFinal(dancer);
                }
            })
        });
    }else{

        // create an array of dancers linked to places
        const R10Array = DistinctTotals.reduce((a,v)=>{

            Object.keys(TotalSumArr).map(dancer=>{
                if(TotalSumArr[dancer]===v){
                    if(!a[v]){
                        a[v]=[dancer]
                    }else{
                        a[v].push(dancer)
                    }
                }
            })
            return a;
        },{});

        //console.log(R10Array)
        // loop through the R10 Array to see how many dancers linked
        Object.keys(R10Array).sort((a,b)=>a-b).map(key=>{

            if(R10Array[key].length===1){
                // only one dancer linked to that total, we can place that dancer with the next available place
                PlaceDanerFinal(R10Array[key][0])
            }
            // two or more dancers, we then too look at runing the rule 10 function
            else{
                Rule10AccessFunction(R10Array[key])
            }
        })
    }
    //console.log(FinalResult)
    if(Object.keys(FinalResult).length ===dancers.length){
        return {
            result:FinalResult,
            totals:TotalSumArr,
            danceResults:DancesResults,
            rule11TableData:Rule11TableData,
            RuleTableData
        }
    }
    /********data
    console.log('finalResult');
    console.log(FinalResult);
    console.log('Totals')
    console.log(TotalSumArr);
    console.log('Rule Table Data')
    console.log(RuleTableData);
     */
};