import React from 'react';
import {unContestedTable} from "./uncontestedAlgo";
import {DanceMarksWithTable, FinalSummary}from './skatingSystem';
const finalTable ={};

//sort out marks

function SortMarks (roundData){
    const marks = roundData.marks;
    const dances = roundData.dances;
    const startList = roundData.activeList;
    const adjuds = Object.keys(marks);

    return dances.reduce((a,dance)=>{
        a[dance]=startList.reduce((ar,dancer)=>{

            ar[dancer] = adjuds.reduce((arr,mark)=>{
                arr.push(marks[mark][dance][dancer]);
                return arr
            },[]);
            return ar
        },{});

        return a;
    },{});
};

// get list of dances won
export const dancesWon=(roundData)=>{
    const marks = roundData.marks;
    const dances = roundData.dances;
    const startList = roundData.activeList;
    const adjuds = Object.keys(marks);
    const sortedMarks = SortMarks(roundData);

   const results= dances.reduce((a,v)=>{
            a[v]=WorkOutMarksWithTable(sortedMarks[v]).results;

        return a;
    },{});

   return dances.reduce((a,v)=>{

       Object.keys(results[v]).map(dancer=>{
          if(results[v][dancer]===1){
              if(a[dancer]){
                  a[dancer].push(v)
              }else{
                  a[dancer]=[v]
              }
          }
       });

       return a;
   },{})

};


//return table
export const marksTableFinal=(roundData)=>{
    //console.log('function Called');
    const marks = roundData.marks;
    const dances = roundData.dances;
    const startList = roundData.activeList;
    const adjuds = Object.keys(marks);

    const sortedMarks = SortMarks(roundData);


    return (
        <div style={{paddingTop:25,paddingBottom:25}}>
            {dances.map(dance=>{
                return (
                    <div key={dance+'table'}>
                        <h4>{dance.toUpperCase()}</h4>
                        <style>{`
                        @media print{
                            table{
                                page-break-inside:auto;
                                page-break-after:auto;
                                position: relative;
                            }
                            th{
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto;
                                position: relative;
                            }
                            tr{
                            position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            td{
                            position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                        }
                                                       
                            th{
                            position: relative;
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                             fontSize:10;
                        page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                                
                            }
                            td{
                            position: relative;
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                              fontSize:10;
                               page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            table{
                            position: relative;
                                border-collapse :collapse;
                                 page-break-inside:auto;
                                page-break-after:auto;
                            }
                            
                          `}
                        </style>
                        <table>
                            <tbody>
                                <tr>
                                    <th>#</th>
                                    {adjuds.map(adj=>{
                                        return (
                                            <th key={adj}>{adj}</th>
                                        )
                                    })}

                                    {startList.map((d,i)=>{
                                        return (
                                            <th key={i}>(1-{i+1})</th>
                                        )
                                    })}
                                    <th>Result</th>
                                </tr>
                                {makePlacingsTable(sortedMarks[dance],dance)}
                            </tbody>
                        </table>


                    </div>
                )


            })}
            <div>
                <h5>Final Summary</h5>
                {makeFinalSummaryTable(roundData)}
            </div>
        </div>
    )



};


function makePlacingsTable (dance, title) {

    const result = WorkOutMarksWithTable(dance).results;

    finalTable[title]=result;

    const tableResult = WorkOutMarksWithTable(dance).tableData;

    //console.log(WorkOutMarksWithTable(dance));

    const dancers = Object.keys(dance);
  return  Object.keys(dance).map(dancer=>{

      return (
          <tr key={dancer+dancer}>
              <td>{dancer}</td>
              {dance[dancer].map((place,i)=>{
                  return <td key={dancer+i}>{place}</td>
              })}

              {//show calculations
                  dancers.map((place,i)=>{
                      //console.log(tableResult[i+1]);
                      if(tableResult[i+1]!== undefined){
                          if(tableResult[i+1][dancer]!== undefined){
                              return <td key={place+i} >{tableResult[i+1][dancer]}</td>
                          }else if(result[dancer]> i+1) {
                              return <td key={place+i}></td>
                          }else{
                              return <td key={place+i} >-</td>
                          }
                      }else{
                          return <td key={place+i}>-</td>
                      }

                  })
              }
              <td>{result[dancer]}</td>

          </tr>
      )

  })
}

function makeFinalSummaryTable(roundData){
    const section = SortMarks(roundData);
    const dancers = roundData.activeList;
    const dances = roundData.dances;
    const finalPlacing = finalSummary(section);


    return (
        <div>
            <table>
                <thead>
                        <tr>
                            <th>#</th>
                            {dances.map(dance=>{
                                return <th key={'fs'+dance}>{dance.charAt(0)}</th>
                            })}
                            <th>Totals</th>
                            <th>Result</th>
                            <th></th>
                        </tr>
                </thead>
                <tbody>
                    {dancers.map(dancer=>{
                        return (
                            <tr key={'fn'+dancer}>
                                <td>{dancer}</td>
                                {dances.map((dance,i)=>{
                                    return <td key={dance+i+dancer}>{finalPlacing.danceResults[dance][dancer]}</td>
                                })}
                                <td>{finalPlacing.totals[dancer]}</td>
                                <td>{finalPlacing.result[dancer]}</td>
                                <td>{finalPlacing.RuleTableData[dancer]!== undefined?finalPlacing.RuleTableData[dancer]:'Rule 9'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {makeRule11Table(finalPlacing.rule11TableData)}
        </div>
    )

}

// make rule 11 table if it exists
function makeRule11Table(data){

    const sortedRule11Data = reOrderRule11(data);
   // console.log(data, sortedRule11Data);


    if(data !=={}){
        const startPlace = Object.keys(data);
        return (
            <div>
                {startPlace.map(key=>{
                    const keys = Object.keys(sortedRule11Data[key]);
                    const r11Dancers = Object.keys(sortedRule11Data[key][keys[0]]);
                    return (
                        <div key={'r11'+key}>
                            <h5>Rule 11 Calculations for position-{key}</h5>
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        {keys.map((ind,i)=>{
                                            if(ind !=='result'){
                                                return (
                                                    <th key={'r11'+key+'-'+ind}>(1-{parseInt(key)+i})</th>
                                                )
                                            }
                                        })}
                                        <th>Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {r11Dancers.map((dancer,i)=>{
                                    return (
                                        <tr key={'r11Dancer'+dancer}>
                                            <td>{dancer}</td>
                                            {keys.map((ind,i)=>{
                                                return (
                                                    <th key={i+'r1101'}>{sortedRule11Data[key][ind][dancer]}</th>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    )
                })}
            </div>
        )
    }


}

// returns final placings for all dancers
export const returnResultsFinal=(roundData)=>{
    //console.log('function Called');
    const dancers = roundData.activeList;
    const sortedMarks = SortMarks(roundData);
    const finalPlacings = finalSummary(sortedMarks);
    const result = finalPlacings.result;
    const table =
        <table>
            <thead>
                <tr>
                    <th>Dancer</th>
                    <th>Placing</th>
                </tr>
            </thead>
            <tbody>
            {dancers.map(dancer=>{
                return (
                    <tr key={'placingsTable'+dancer}>
                        <td>{dancer}</td>
                        <td>{result[dancer]}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
    return ({table:table,result:result})
};

export const returnLeftPLacing=(totals,dancers,callbacks)=>{

    const placingResult={};
    let availablePlacings = dancers.reduce((a,v,i)=>{
        a.push(i+1);
        return a;
    },[]);

    callbacks.map((n,i)=>{
        availablePlacings = availablePlacings.filter(p=>{
            if(p!== availablePlacings[0]){
                return p;
            }
        })
    });

    const dancersLeft = dancers.reduce((a,v)=>{

        if(!Object.keys(totals).includes(v)){
            a[v]=0;
        }else if(!callbacks.includes(v)){
            a[v]=totals[v]
        }
        return a;
    },{});

    const distinctCallBacks =[...new Set(Object.values(dancersLeft))];
    //console.log(distinctCallBacks.sort((a,b)=>b-a))

    // number of unique calls equals the number of dancers left, we can place
    if(distinctCallBacks.length === Object.keys(dancersLeft).length){

        distinctCallBacks.sort((a,b)=>b-a).map(call=>{
            Object.keys(dancersLeft).map(dancer=>{
                if(dancersLeft[dancer]=== call){
                    // we can place with next placing
                    const place = availablePlacings[0];
                    placingResult[dancer]=place;
                    availablePlacings = availablePlacings.filter(p=>{
                        if(p!== place){
                            return p;
                        }
                    })
                }
            })
        })
    }
    // if not then we need to see which dnacers are linked wih which call
    // then place acordingly
    else{

        const distinctCallBackDancer = distinctCallBacks.reduce((a,v)=>{

            Object.keys(dancersLeft).map(d=>{
                if(dancersLeft[d]===v){
                    if(!a[v]){
                        a[v]=[d]
                    }else{
                        a[v].push(d)
                    }
                }
            })

            return a;
        },{});
        //console.log(distinctCallBackDancer)

        Object.keys(distinctCallBackDancer).sort((a,b)=>b-a).map(callback=>{
            // only one dancer can place and move on
            if(distinctCallBackDancer[callback].length===1){

                const dancer = distinctCallBackDancer[callback][0];
                const place = availablePlacings[0];
                placingResult[dancer]=place;
                availablePlacings=availablePlacings.filter(p=>{
                    if(p!==place){
                        return p
                    }
                });
            }else{
                // multiple dancers, need to then work out place and place
                //console.log(distinctCallBackDancer[callback])
                const placeSum = distinctCallBackDancer[callback].reduce((a,v)=>{

                    if(isNaN(a)){
                        a=availablePlacings[0]
                    }else{
                        a+=availablePlacings[0]
                    }
                    availablePlacings=availablePlacings.filter(p=>{
                        if(p!==availablePlacings[0]){
                            return p
                        }
                    });

                    return a;
                },{});
                const place = placeSum/Object.keys(distinctCallBackDancer[callback]).length
                distinctCallBackDancer[callback].map(dancer=>{
                    placingResult[dancer]=place;
                })
            }
        })
    }
    if(Object.keys(placingResult).length === Object.keys(dancersLeft).length){
        return placingResult;
    }
    //console.log(placingResult);

}

// return final results for result page;
export const returnFinalResult=(rounds)=>{
    const roundKeys=Object.keys(rounds);
    const allDancers = rounds[roundKeys[0]].activeList;
    const finalPlacings=[];
    let tempDancers=[];
    let howManyMissing=0;
    let nextPosition=0;
    let roundsName=Object.keys(rounds);



    // find final
    const finalKey = roundKeys.reduce((a,v)=>{
        if(rounds[v].roundType==='Final'){
            a=v
        }
        return a;
    },'');



    // check if all dancers from start list are there
    if(rounds[finalKey].activeList.length === rounds[finalKey].startList.length){
        // if they are then just place
        finalPlacings.push(rounds[finalKey].results)
    }
    else{
        // if not then find the missing dancers and add to next section
        tempDancers=rounds[finalKey].startList.reduce((a,v)=>{

            if(!rounds[finalKey].activeList.includes(v)){
                a.push(v)
            }

            return a;
        },[]);

        nextPosition=rounds[finalKey].activeList.length+1;
        howManyMissing=tempDancers.length;

        finalPlacings.push(rounds[finalKey].results)
    }

    //remove round name from roundsName
    roundsName = roundKeys.filter(n=>{
        if(n !== finalKey){
            return n
        }
    });

    // loop through remaining rounds
    roundsName.sort().reverse().map(round=>{
        let tempPlacings={};
        roundsName = roundsName.filter(r=>{
            if(r !== round){
                return r
            }
        });
        if(tempDancers.length !==0){
            // there is a temp dancer, they need to be placed first then followed by further dancers
            if(tempDancers.length===1){
                // first check to see if only one dancer, they win the next place
                tempPlacings[tempDancers[0]]=nextPosition;
                // need to set next pos
                nextPosition +=1

            }else{

                // if more than 1 then we need to make a place sum
                const placeSum =  tempDancers.reduce((a,v)=>{
                    if(a===0){
                        a=nextPosition
                    }else{
                        a+=(nextPosition+1)
                    }

                    return a;
                },0);
                const place = placeSum/tempDancers.length;
                tempDancers.map(dancer=>{
                    nextPosition+=1;
                    tempPlacings[dancer]=place
                });
            }

        }
        tempDancers=[];

        if(rounds[round].roundNo !==1){
            if(rounds[round].startList !== rounds[round].activeList){
                // find the missing dancers
                tempDancers=rounds[round].startList.reduce((a,v)=>{

                    if(!rounds[round].activeList.includes(v)){
                        a.push(v)
                    }
                    return a;
                },[]);
            }
        }
        if(rounds[round].placingResult!== undefined){
            Object.keys(rounds[round].placingResult).map(dancer=>{
                nextPosition+=1;
                if(rounds[round].roundNo ===1){
                    tempPlacings[dancer]=rounds[round].placingResult[dancer];
                }else{
                    tempPlacings[dancer]=rounds[round].placingResult[dancer];
                }

            });
        }

        howManyMissing = tempDancers.length;
        finalPlacings.push(tempPlacings);


    });


    return finalPlacings;
};

// functions to scrutineer system

function reOrderRule11(data){

    // loop through all the rule 11 scenarios as keys(start Positions)
   return Object.keys(data).reduce((a,v)=>{

       const position =v;

       const finalPos= data[v].length;


       const dancers = data[v].reduce((ar,val,i)=>{
           if(i+1 !== finalPos){
               val.map(p=>{
                   const dancer=Object.keys(p)[0];
                   if(!ar.includes(dancer)){
                       ar.push(dancer);
                   }
               })
           }
           return ar;
       },[]).sort((a,b)=>a-b);

       a[position]=data[v].reduce((ar,val,i)=>{

           if(i+1 !== finalPos){
               if(val.length>0){

                   const actualResult=val.reduce((arr,pos)=>{
                       const entry = Object.entries(pos);
                       const dancer = entry[0][0];
                       const place=entry[0][1];

                       arr[dancer]=place;
                       return arr;
                   },{});


                   if(Object.keys(actualResult).length !== dancers.length){
                       // need to find the missing dancers
                       dancers.map(dancer=>{
                           if(!Object.keys(actualResult).includes(dancer)){
                               actualResult[dancer]='';
                           }
                       });
                       ar[i+1]=actualResult;
                   }else{
                       ar[i+1]=actualResult;
                   }


               }else{
                   ar[i+1]=dancers.reduce((arr,dan)=>{

                       arr[dan]='';
                       return arr;
                   },{})
               }



           }else{
               ar['result']=val
           }
           return ar;
       },{})


       return a;
   },{})



}
//dancer marks with table data;
function WorkOutMarksWithTable2(dance){
    const dancers = Object.keys(dance);
    const result={};

    const majFunc = ()=>{
        function testOdd(n){

            if(n&1){
                // odd
                return true
            }else{
                // even
                return false
            }

        }
        const adjuLength = dance[dancers[0]].length;
        const majDiv = Math.ceil(dance[dancers[0]].length/2);
        if(testOdd(testOdd(adjuLength))){
            //true === is odd therfore no need to add the extra 1
            return majDiv;
        }else{
            //false is even need to add one before retrurning
            return majDiv +1;
        }

    }

    const majority=majFunc();
    const finalTableData=[];


    let tableData=[];
    let dancerNum =1;
    let currentPlace=1;
    let tempPlace=0;
    let tempPlacing={};

    function reset(place){
        if(place === dancers.length && Object.keys(result).length !== dancers.length){
            currentPlace=place-1
        }else{
            currentPlace=place;
        }

        tableData=[];
        tempPlacing={};
        tempPlace=0;
        dancerNum=1;
    };

    let availablePlacings = dancers.reduce((a,v,i)=>{
        a.push(i+1);
        return a;
    },[]);

    //count placings
    function countPlacings(marks,place){
        return marks.reduce((a,v)=>{
            if(v<=parseInt(place)){
                if(isNaN(a)){
                    a=1
                }else{
                    a+=1
                }
            }

            return a;
        },{})
    }
    // add to temp
    function addToTemp(dancer,count){
        tempPlacing[dancer]=count
    }
    //run through all dancers and count placings
    function checkDancers(){
        if(currentPlace <= dancers.length){
            dancers.map(d=>{
                dancerNum+=1;

                if(result[d]=== undefined){
                    let count = countPlacings(dance[d],currentPlace);
                    if(currentPlace===dancers.length && Object.keys(result).length !==dancers.length ){
                        const newPlace=currentPlace-1;
                        count=countPlacings(dance[d],newPlace)
                    }
                    if(!isNaN(count)){
                        if(count>=majority){
                            addToTemp(d,count);
                        }else{
                            tableData.push({[d]:count});
                        }
                    }
                }
            });
            if(dancerNum-1 === dancers.length){
                accessTempPlacings();
            }
        }
    }
    // handle final table data
    function handleFinalTableData(){
        //console.log(tempPlace);
        //console.log(currentPlace);
        //console.log(finalTableData);
        //console.log('br');
        let loopNum = 0;
        if(tempPlace !== currentPlace && tempPlace !== 0){
            loopNum = tempPlace;
        }else{
            loopNum = currentPlace;
        }

        if(finalTableData[loopNum-1]!== undefined){
            tableData.map(dancer=>{

                finalTableData[loopNum-1].push(dancer);
            })
            //finalTableData[loopNum-1].push(tableData);
        }else{
            finalTableData.push(tableData);
        }

        //console.log(loopNum);

    }

    //add up placings
    function addUpPlacings(dancers, place){
        return dancers.reduce((a,v)=>{
            a[v]=dance[v].reduce((ar,val)=>{
                if(val <= place){
                    if(isNaN(ar)){
                        ar =parseInt(val);
                    } else{
                        ar=ar+parseInt(val)
                    }
                }
                return ar;
            },{});
            return a;
        },{})
    }


    //access tempPlacings
    function accessTempPlacings(){
        const tempPlacingDancers = Object.keys(tempPlacing);
        //console.log(tempPlacingDancers);
        //more than one dancer
        if(tempPlacingDancers.length >1){
            handleTempPlaceMulti(tempPlacingDancers)
        }
        //only one dancer
        else if(tempPlacingDancers.length ===1){
            const dancer = tempPlacingDancers[0];
            const place = availablePlacings[0];
            result[dancer]=place;
            availablePlacings=availablePlacings.filter(p=>{
                if(p!== place){
                    return p
                }
            });
            tableData.push({[dancer]:tempPlacing[dancer]});

            handleFinalTableData();

            /*if(finalTableData[currentPlace-1] !== undefined){


                  finalTableData[currentPlace-1].push(tableData);
            }else{
              finalTableData.push(tableData);
            }*/

            const nextPlace = availablePlacings[0];
            reset(nextPlace);

        }
        //no dancers
        else{

            if(tempPlace===0){
                tempPlace = currentPlace+1;
                if(finalTableData[currentPlace-1] === undefined){
                    finalTableData.push(tableData);
                }else{
                    finalTableData[currentPlace]=tableData;
                }
                //finalTableData[currentPlace]=tableData;
            }else{
                handleFinalTableData();
                /*
                if(finalTableData[tempPlace-1]===undefined){
                      finalTableData.push(tableData);
                }else{
                      finalTableData[tempPlace-1].push(tableData);
                }*/
                //finalTableData[tempPlace]=tableData;
                tempPlace+=1;
            }
            handleNoDancers(tempPlace);
        }
    }


    // handle no dancers in temp array
    function handleNoDancers(place){
        let dancerCount =1;
        //console.log('handle No Dancers');
        tableData=[];
        dancers.map(d=>{
            dancerCount +=1;
            if(result[d]===undefined){
                const count = countPlacings(dance[d],place);
                if(!isNaN(count)){
                    if(count >= majority){
                        addToTemp(d,count)
                    }else{
                        tableData.push({[d]:count})
                    }
                }
            }


        });
        if(dancerCount-1 === dancers.length){
            accessTempPlacings();
        }
    }
    //handle multiDancers
    function handleTempPlaceMulti(array){
        const arrayLength=array.length;

        const distinctPlaces =[...new Set(array.map(dancer=>tempPlacing[dancer]))];

        // test distinct places to see if length matches array
        if(arrayLength === distinctPlaces.length){
            // if true, all dancers have a different majority, place from highest to lowest
            //loop through dancers and mathc majority with dancer(s)

            distinctPlaces.sort((a,b)=>b-a).map(maj=>{
                array.map(dancer=>{
                    if(tempPlacing[dancer]===maj){
                        const place = availablePlacings[0];
                        result[dancer]=place;
                        availablePlacings = availablePlacings.filter(p=>{
                            if(p!== place){
                                return p;
                            }
                        });
                        tableData.push({[dancer]:maj});
                    }
                })

            });
            handleFinalTableData();
            //finalTableData[tempPlace]=tableData;
            tempPlace=0;

            reset(availablePlacings[0])
        }
        //
        else{
            // if false
            // first check to see how many dancers have the same majority
            // expect eg:{majorNum[3]:[dancer1,dancer2], majorNum[4]:dancer[3]}
            const sameMajTest = distinctPlaces.reduce((a,v)=>{
                array.map(dancer=>{
                    if(tempPlacing[dancer]===v){
                        if(!a[v]){
                            a[v]=[dancer]
                        }else{
                            a[v].push(dancer)
                        }
                    }

                });
                return a;

            },{});

            //arrange maj from high to low
            Object.keys(sameMajTest).sort((a,b)=>b-a).map(key=>{

                //only one dancer to that majority, place dancer with next availble position
                if(sameMajTest[key].length ===1){
                    const dancer = sameMajTest[key][0];
                    const place = availablePlacings[0];
                    result[dancer]=place;
                    availablePlacings = availablePlacings.filter(p=>{
                        if(p!== place){
                            return p;
                        }
                    });
                    tableData.push({[dancer]:tempPlacing[sameMajTest[key]]})
                    //tableData[dancer]=tempPlacing[sameMajTest[key]];
                    delete tempPlacing[dancer];
                    delete sameMajTest[key];
                }else{
                    // need to add up totals of places of test palce and less

                    // set place number to add up of
                    let addPlaceNum ='';
                    if(tempPlace===0){
                        addPlaceNum=currentPlace
                    }else{
                        addPlaceNum=tempPlace;
                    }
                    // add the places for the dancers and compare
                    const placesAdded = addUpPlacings(sameMajTest[key], addPlaceNum);

                    const placesAddedCheck = [...new Set(Object.values(placesAdded))];

                    //check if after adding places up there is still a tie
                    // check length of distinct number compared to the dancers sent to add func


                    if(placesAddedCheck.length === Object.keys(placesAdded).length){
                        //console.log(placesAddedCheck.sort((a,b)=>a-b));
                        //if true then adding up the marks has seprated the dancers and we can place
                        placesAddedCheck.sort((a,b)=>a-b).map(add=>{
                            Object.keys(placesAdded).map(dancer=>{
                                if(placesAdded[dancer]=== add){
                                    const place = availablePlacings[0];
                                    result[dancer]=place;
                                    availablePlacings = availablePlacings.filter(p=>{
                                        if(p!==place){
                                            return p;
                                        }
                                    });
                                    tableData.push({[dancer]:tempPlacing[dancer]+'('+placesAdded[dancer]+')'})
                                    delete tempPlacing[dancer];
                                }
                            });
                        });
                        //console.log(result);
                        handleFinalTableData();

                        reset(availablePlacings[0]);
                    }
                    //if false then we need to first check to see which dancers are linked to that added value
                    //then move to the next position
                    else{
                        // need to add dancers to table data with addition marks
                        Object.keys(placesAdded).map(dancer=>{
                            tableData.push({[dancer]:tempPlacing[dancer]+'('+placesAdded[dancer]+')'})
                            //tableData[dancer]=tempPlacing[dancer]+'('+placesAdded[dancer]+')';
                        });

                        handleFinalTableData();

                        // first check to see how many dancers are linked to the placesadded check
                        const secondCheck = placesAddedCheck.reduce((a,v)=>{

                            sameMajTest[key].map(dancer=>{
                                if(placesAdded[dancer]===v){
                                    if(!a[v]){
                                        a[v]=[dancer]
                                    }else{
                                        a[v].push(dancer)
                                    }
                                }
                            });

                            return a;
                        },{});
                        // now loop through the second check and see if it has two then move to nex place

                        //console.log(secondCheck);
                        Object.keys(secondCheck).map((addVal,i)=>{
                            if(secondCheck[addVal].length ===1){
                                // only one dancers relating to that added value
                                if(i ===0){
                                    // the first number in the array, wins the place

                                    const dancer = secondCheck[addVal][0];
                                    const place = availablePlacings[0];
                                    result[dancer]=place;
                                    availablePlacings = availablePlacings.filter(p=>{
                                        if(p!== place){
                                            return p
                                        };
                                    });

                                }else{
                                    // comes last out of the places competiting

                                    const amountOfDancers = sameMajTest[key].length;

                                    const dancer = secondCheck[addVal][0];
                                    const place = availablePlacings[amountOfDancers-1];
                                    result[dancer]=place;
                                    availablePlacings = availablePlacings.filter(p=>{
                                        if(p!== place){
                                            return p
                                        };
                                    });
                                    const nextPlace = availablePlacings[0];
                                    if(nextPlace !== undefined){
                                        reset(nextPlace);
                                    }else{
                                        reset(0);
                                    }

                                }
                            }else{

                                if(tempPlace===0){
                                    tempPlace=currentPlace+1
                                }else{
                                    tempPlace +=1
                                }
                                if(tempPlace >dancers.length){
                                    const placeSum = sameMajTest[key].reduce((a,v,i)=>{
                                        if(isNaN(a)){
                                            a=availablePlacings[i]
                                        }else{
                                            a += availablePlacings[i]
                                        }
                                        return a;
                                    },{});

                                    array.map((dancer,i)=>{
                                        result[dancer]=placeSum/array.length;
                                        availablePlacings=availablePlacings.filter(val=>{
                                            if(val !== availablePlacings[0]){
                                                return val;
                                            }
                                        });
                                        delete tempPlacing[dancer];
                                    });

                                    const nextPlace = availablePlacings[0];
                                    if(nextPlace !== undefined){
                                        reset(nextPlace);
                                    }else{
                                        reset(0);
                                    }

                                }else{
                                    // move to next position
                                    tempPlacing={};
                                    tableData=[];
                                    let DancerCount = 1;


                                    secondCheck[addVal].map(dancer=>{
                                        DancerCount +=1;
                                        if(result[dancer]===undefined){
                                            const count = countPlacings(dance[dancer], tempPlace);
                                            if(!isNaN(count)){
                                                if(count >= majority){
                                                    addToTemp(dancer,count)
                                                }else{
                                                    tableData.push({[dancer]:count})
                                                    //tableData[dancer]=count;
                                                }
                                            }

                                        }

                                    });
                                    if(DancerCount-1 === sameMajTest[key].length){
                                        accessTempPlacings();
                                    }


                                }


                            }
                        })


                    }



                }
                currentPlace=availablePlacings[0];
            });

        }


    }


    Object.keys(dance).map((d,i)=>{
        const num = i+1;
        //console.log('loopNumber:'+num);
        if(Object.keys(result).length !== dancers.length){
            tempPlace=currentPlace;
            checkDancers();
        }
    });


    if(Object.keys(result).length === dancers.length){

        const sortedFinalTableData = finalTableData.reduce((a,v,i)=>{

            a[i+1]= finalTableData[i].reduce((ar,val,ind)=>{

                ar[Object.keys(finalTableData[i][ind])]= Object.values(finalTableData[i][ind]).toString()


                return ar;
            },{});

            return a;
        },{});


        return {results:result,tableData:sortedFinalTableData};
    }
    /*
    console.log('--------------------------------------------------------')
    console.log('result')
    console.log(result);
    console.log('tabledata');
    console.log(tableData);
    console.log('finalTableData');
    console.log(finalTableData);
    */

}

function WorkOutMarksWithTable(dance){
    return DanceMarksWithTable(dance)
}


// final summary function
function finalSummary2(section){

    //constants
    const dances = Object.keys(section);
    const dancers = Object.keys(section[dances[0]]);
    const resultsSum={};

    // rule 11 table
    const rule11Table ={};

    // variables
    let finalPlacing ={};
    let currentPlace=1;
    let templace=0;
    let availablePlacings= dancers.reduce((a,v,i)=>{
        a.push(i+1);
        return a;
    },[]);

    //add results to final result object
    dances.map((dance)=>{
        resultsSum[dance]= WorkOutMarksWithTable(section[dance]).results
    });


    const resultArr = dancers.reduce((a,v)=>{

        dances.map(dance=>{
            if(!a[v]){
                a[v]=[resultsSum[dance][v]]
            }else{
                a[v].push(resultsSum[dance][v])
            }
        });

        return a;
    },{});
    //console.log('result arr')
    //console.log(resultArr);

    function countPlacings(dancer, place){
        return resultArr[dancer].reduce((a,v)=>{
            const accessPlace = Math.ceil(v);
            if(accessPlace <=place){
                if(isNaN(a)){
                    a=1
                }else{
                    a+=1
                }
            }else{
                if(isNaN(a)){
                    a=0
                }
            }
            return a;
        },{})
    }

    function r10AddPlacing(dancer,place){
        return resultArr[dancer].reduce((a,v)=>{
            const accessPlace=Math.ceil(v);

            if(accessPlace<=place){
                a+=accessPlace;
            }
            return a;
        },0);
    }

    function rule10(array){
        // first step count the number of placings relating to the current place and higher(lower number)
        const tempArrayCount = array.reduce((a,v)=>{
            a[v]=countPlacings(v,currentPlace);
            return a;
        },{});
        // create an array based on those counts
        const distinctPlaceCount = [...new Set(Object.values(tempArrayCount))];


        // test to see if the counts have split the position
        // if true then we can place those dancers, return object with placings 1,2 ect (112:1,113:2)
        if(distinctPlaceCount.length === array.length){
           // console.log(distinctPlaceCount.sort((a,b)=>b-a));
            return  distinctPlaceCount.sort((a,b)=>b-a).reduce((a,v,i)=>{
                array.map(dancer=>{
                    if(v === tempArrayCount[dancer]){
                        a[dancer]= i+1
                    }
                });
                return a;
            },{})
        }
        // if false, then we proceed to rule 10 add placings for those dancers
        else{

            const r10PlaceCount = array.reduce((a,v)=>{
                a[v]=r10AddPlacing(v,currentPlace);
                return a;
            },{});

            const r10DistinctPlaceAdd=[...new Set(Object.values(r10PlaceCount))];

            if(r10DistinctPlaceAdd.length === array.length){
                // adding up places has split the tie and we can then return for placing;

             return  r10DistinctPlaceAdd.sort((a,b)=>a-b).reduce((a,v,i)=>{

                    Object.keys(r10PlaceCount).map(dancer=>{

                        if(r10PlaceCount[dancer]===v){
                            a[dancer]=i+1
                        }

                    });
                    return a;
                },{})


            }else{
                // move on to rule 11
                // we need to check how many dancers are linked to that count
                const distinctPlaceCountCheck = distinctPlaceCount.sort((a,b)=>b-a).reduce((a,v)=>{

                    array.map(dancer=>{
                        if(v === tempArrayCount[dancer]){
                            if(!a[v]){
                                a[v]=[dancer]
                            }else{
                                a[v].push(dancer)
                            }
                        }
                    })

                    return a;
                },{});



                let rule11Return = null;

                Object.keys(distinctPlaceCountCheck).sort((a,b)=>b-a).map((k,ind)=>{

                    //console.log(distinctPlaceCountCheck[k]);
                    if(distinctPlaceCountCheck[k].length >1 && ind===0){
                        //rule 11 function
                        //console.log('rule 11 Called for - '+distinctPlaceCountCheck[k]);


                        const R11 = rule11(distinctPlaceCountCheck[k], currentPlace);
                        //console.log(R11);
                        // if only two dancers entered into rule 11 then both win places
                        // if not then we need to revert back to rule 10 for the remainder dancers
                        if(distinctPlaceCountCheck[k].length === 2){
                            // check there is no tie from rule 11
                            if(Object.keys(R11.result).length ===1){

                                const placeWinner = Object.keys(R11.result)
                                rule11Return = distinctPlaceCountCheck[k].reduce((a,v)=>{

                                    if(parseInt(placeWinner)=== parseInt(v)){
                                        a[v]=R11.result[placeWinner]
                                    }else{
                                        a[v]=R11.result[placeWinner]+1
                                    }

                                    return a;
                                },{})
                            }
                            // if there is a tie then just return result
                            else{
                                rule11Return=R11.result;
                            }
                        }else{
                            rule11Return=R11.result;
                        }

                        R11.tableData.push({result:rule11Return})
                        rule11Table[currentPlace]=R11.tableData;

                    }
                });




                if(rule11Return !== null){


                    return rule11Return
                }
            }

        }

    }

    function rule11(array, startPlace){

        const rule11Section = array.reduce((a,v)=>{
            a[v]= dances.reduce((ar,val)=>{
                section[val][v].map(num=>{
                    ar.push(num)
                })
                return ar
            },[])

            return a;
        },{})

        const majFunc = ()=>{

            const adjusLength = rule11Section[array[0]].length

            const majDiv = Math.ceil(adjusLength/2);

            if(!!(adjusLength&1)){
                //true === is odd therfore no need to add the extra 1
                return majDiv;
            }else{
                //false is even need to add one before retrurning
                return majDiv +1;
            }

        };
        const majority = majFunc();
        const finalTableData = [];
        const result ={};
        let tempArr ={};
        let tempPlaceR11 =0;
        let tableData=[];
        let tableRowNum=0;
        let complete = false;

        // count placings
        function countPlaceR11(dancer, place){
            return  rule11Section[dancer].reduce((a,v)=>{

                if(v <=place){
                    if(isNaN(a)){
                        a=1
                    }else{
                        a+=1
                    }
                }else{
                    if(isNaN(a)){
                        a=0
                    }
                }
                return a;
            },{})
        }

        // add place to temp array
        function addToTemparr(dancer,count){
            tempArr[dancer]=count;
        }
        // add data to table data
        function addDataToTable(dancer, count){
            tableData.push({[dancer]:count});
        }
        // add table data to final table data
        function addTableDataToFinal(){

            finalTableData.push(tableData);
            tableData=[];
        }

        // handle no dancers
        function handleNoDancers(place){
            //console.log('handle no dancers');
            let dancerCount = 0;
            array.map(dancer=>{
                dancerCount+=1;
                const count = countPlaceR11(dancer,place);
                if(count >=majority){
                    addToTemparr(dancer,count)
                }else{
                    addDataToTable(dancer,count)
                }
            });
            if(dancerCount === array.length){

                accessTempArr();
            }
        }

        // add up placings
        function addUpPlacingsR11(dancers, placeNum){
            return dancers.reduce((a,v)=>{

                a[v]=rule11Section[v].reduce((ar,val)=>{
                    if(val <= placeNum){
                        if(isNaN(ar)){
                            ar = parseInt(val)
                        }else{
                            ar = ar+parseInt(val)
                        }
                    }
                    return ar;
                },{})

                return a;
            },{})
        }

        // handle temp array with multiple dancers
        function handleTempArrMulti(dancersArr){
            // console.log('handle multiple array')
            const distinctPlaces = [...new Set(Object.values(tempArr))]

            // if distinct places has the same amount of places as the array then tie has been split and return values
            if(distinctPlaces.length === dancersArr.length){
                // need to add to table data and place
                dancersArr.map(dan=>{
                    if(tempArr[dan]=== distinctPlaces.sort((a,b)=>b-a)[0]){
                        result[dan]=currentPlace
                    }
                })
                //handle table data
                dancersArr.map(danc=>{
                    addDataToTable(danc,tempArr[danc])
                });

                addTableDataToFinal();

                complete = true

            }
            //if false then we need to run through the different placings and see how many dancers are in that placing
            else{
                // first check to see how many dancers have teh same majority
                // expect eg:{majNum[3]:[dancer1,dancer2], majNum[4]:[dancer3]}

                const sameMajTest = distinctPlaces.reduce((a,v)=>{
                    array.map(dancer=>{
                        if(tempArr[dancer]===v){
                            if(!a[v]){
                                a[v]=[dancer]
                            }else{
                                a[v].push(dancer)
                            }
                        }

                    });
                    return a;

                },{});
                // arrange maj from high to low and test how many are in that array


                Object.keys(sameMajTest).sort((a,b)=>b-a).map((key,index)=>{

                    if(sameMajTest[key].length ===1){
                        // only one dancer with that array
                        if(index ===0){
                            // only one dancer and majority is the first one in the list
                            // therefore we can place and no need to go further
                            const dancer = sameMajTest[key][0];
                            const place = currentPlace;

                            // dont for get to add the data to the table data and the final table data
                            dancersArr.map(dancer=>{
                                addDataToTable(dancer, tempArr[dancer]);
                            });

                            addTableDataToFinal();
                            complete=true;
                        }

                    }else{
                        // we need to add up places of place and less
                        let addPlaceNum = '';
                        if(tempPlaceR11 ===0){
                            addPlaceNum=startPlace;
                        }else{
                            addPlaceNum=tempPlaceR11;
                        }
                        // places added array
                        const placesAdded = addUpPlacingsR11(sameMajTest[key],addPlaceNum);

                        const placesAddedCheck = [... new Set(Object.values(placesAdded))];

                        //console.log(placesAddedCheck.sort((a,b)=>a-b))
                        // check if after adding places up there is still a tie
                        // check length of distinct places added check compared to the dancers sent to the add function
                        if(placesAddedCheck.length === Object.keys(placesAdded).length){
                            // if true adding up the places has broken the tie and we can place
                            Object.keys(placesAdded).map(dancer=>{
                                addDataToTable(dancer,tempArr[dancer]+'('+placesAdded[dancer]+')')
                                if(placesAdded[dancer]=== placesAddedCheck.sort((a,b)=>a-b)[0]){
                                    result[dancer]=startPlace;

                                }
                            });

                            addTableDataToFinal();
                            complete=true;

                        }else{
                            // if false we need to run the same test for the next position
                            Object.keys(placesAdded).map(dancer=>{
                                addDataToTable(dancer,tempArr[dancer]+'('+placesAdded[dancer]+')');
                            });
                            addTableDataToFinal();

                            if(tempPlaceR11 ===0){
                                tempPlaceR11=startPlace
                            }else{
                                tempPlaceR11+=1
                            }

                            if(tempPlaceR11 > dancers.length){
                                // we have to tie overall

                                const placeSum = sameMajTest[key].reduce((a,v,i)=>{
                                    if(isNaN(a)){
                                        a=availablePlacings[i]
                                    }else{
                                        a +=availablePlacings[i]
                                    }
                                    return a
                                },{});

                                array.map((dancer,i)=>{
                                    result[dancer]=placeSum/array.length;
                                    // might need to remove placings here
                                });

                                complete=true;

                            }else{
                                tempArr = {};
                                tableData=[];
                                let DancerCount =0;

                                sameMajTest[key].map(dancer=>{
                                    DancerCount +=1;
                                    const count = countPlaceR11(dancer, tempPlaceR11);
                                    if(!isNaN(count)){
                                        if(count >= majority){
                                            addToTemparr(dancer,count);
                                        }else{
                                            addDataToTable(dancer, count);
                                        }
                                    }
                                });
                                if(DancerCount === sameMajTest[key].length){
                                    accessTempArr();
                                }
                            }


                        }
                    }


                })

            }
        }

        // access temp array
        function accessTempArr(){
            const tempArrDancers = Object.keys(tempArr);
            //console.log(tempArrDancers);
            //more than one dancer
            if(tempArrDancers.length >1){
                handleTempArrMulti(tempArrDancers);
            }
            // only one dancer
            else if(tempArrDancers.length ===1){

                addDataToTable(tempArrDancers[0], tempArr[tempArrDancers[0]]);

                addTableDataToFinal();


                result[tempArrDancers[0]]=startPlace;

                complete =true;

            }
            // no dancers
            else{
                if(tempPlaceR11===0){
                    tempPlaceR11 = startPlace+1
                }else{
                    tempPlaceR11+=1
                }
                addTableDataToFinal();
                tableData=[];
                handleNoDancers(tempPlaceR11)
            }
        }
        dancerCount();
        // loop through all the dancers in the array and count placings
        function dancerCount(){
            let dancerCount = 0;

            array.map(dancer=>{
                dancerCount+=1;
                const count = countPlaceR11(dancer,startPlace);

                if(count >=majority){
                    addToTemparr(dancer,count)
                }else{
                    addDataToTable(dancer,count)
                }
            });
            if(dancerCount === array.length){
                accessTempArr()
            }
        }


        if(complete){
            return (({result:result,tableData:finalTableData}))
        }

    }

    function handleReturnRule10(accessArr, array){

        const accessArrKeys = Object.keys(accessArr);

        // only one number returned, meaning we found a way to split using rule 10 & 11
        // the rest of the dancers need to go back into the pot for marking
        if(Object.keys(accessArr).length===1){
            const dancer = Object.keys(accessArr);
            const place = availablePlacings[0];
            // place dancer in final placing
            finalPlacing[dancer]=place;
            availablePlacings = availablePlacings.filter(p=>{
                if(p!== place){
                    return p;
                }
            });
            if(availablePlacings.length !==0){
                currentPlace=availablePlacings[0];
                // new array test
                const newArray = array.filter(d=>{
                    if(parseInt(d) !== parseInt(dancer)){

                        return d;
                    }
                });

                handleMultiRule10(newArray);
            }

        }
        // only 2 dancers were accessed for rule 11 and can be placed
        else if(Object.keys(accessArr).length===2 && Object.keys(accessArr).length === array.length){
            const distinctPlace = [...new Set(Object.values(accessArr))];

            // test to see if marks are the same or not;
            if(distinctPlace.length === Object.keys(accessArr).length){
                // if true then both dancers have a different place

                // place dancer with mark relating
                distinctPlace.sort((a,b)=>a-b).map(pl=>{
                    Object.keys(accessArr).map(dancer=>{

                        if(pl === accessArr[dancer]){
                            const place = availablePlacings[0];
                            finalPlacing[dancer]=place;
                            availablePlacings=availablePlacings.filter(p=>{
                                if(p!==place){
                                    return p
                                }
                            });
                        }
                    })
                });
                currentPlace = availablePlacings[0];

            }else{
                // if false then both dancers are tied under rule 11 for that place
                const place1 = availablePlacings[0];
                const place2 = availablePlacings[1];
                const placeSum = (place1+place2)/2;

                Object.keys(accessArr).map((dancer,i)=>{
                    finalPlacing[dancer]=placeSum;
                    availablePlacings = availablePlacings.filter(p=>{
                        if(p!== availablePlacings[i]){
                            return p;
                        }
                    })
                });

                currentPlace = availablePlacings[0];


            }

        }

        else{
            /*
            if returned arr has 2 or more but is not equal to array
            we need to place those returned and create a new array
            with the remaining dancers and access again with rule 10
            and repeat
            */
            const distinctPlaceTest = [... new Set(Object.values(accessArr))]


            // sort out placings of dancers returned by rule 11 1st
            if(distinctPlaceTest.length === Object.keys(accessArr).length){
                // meaning we dont have a tie then we can place those dancers

                Object.keys(accessArr).map((dancer,i)=>{
                    finalPlacing[dancer]=accessArr[dancer];
                    availablePlacings = availablePlacings.filter(p=>{
                        if(p !==accessArr[dancer]){
                            return p
                        }
                    })
                });

                currentPlace= availablePlacings[0];



            }else{
                // if false, we have a tie and need to split the place

                accessArrKeys.map((d,i)=>{
                    finalPlacing[d]=accessArr[d];
                    availablePlacings=availablePlacings.filter(p=>{
                        if(p!== availablePlacings[0]){
                            return p
                        }
                    })
                });



                currentPlace = availablePlacings[0];
            }

            // then remove those dancers from the sent array and try again
            const newArray = array.reduce((a,v)=>{

                if(!Object.keys(accessArr).includes(v)){
                    a.push(v);
                }

                return a;
            },[]);

            if(newArray.length>=1){
                handleMultiRule10(newArray);
            }

        }
    }


    function handleMultiRule10(array){

        handleReturnRule10(rule10(array), array);
    }

    // step one add up placings for each dancer
    const totals = dancers.reduce((a,v)=>{
        a[v]= resultArr[v].reduce((a,b)=>a+b,0)
        return a;
    },{});
    //console.log('totals');
    //console.log(totals);

    // test for distinct values in totals
    const distinctTotals = [...new Set(Object.keys(totals).map(dancer=>totals[dancer]))]

    //console.log('distinct totals')
    //console.log(distinctTotals.sort((a,b)=>a-b))

    // check if there are the same unique values as there are dancers
    //if true then place in acordance with position
    if(distinctTotals.length === dancers.length){

        finalPlacing = distinctTotals.sort((a,b)=>a-b).reduce((a,v,i)=>{

            dancers.map(dancer=>{
                if(totals[dancer]===v){
                    a[dancer]=i+1
                }
            });

            return a;
        },{})
        //console.log(finalPlacing);
    }
    else{
        // if false we need to then see how many dancers have the same total

        const distinctTotalsAcess = distinctTotals.reduce((a,v)=>{

            dancers.map(dancer=>{
                if(totals[dancer]===v){
                    if(!a[v]){
                        a[v]=[dancer]
                    }else{
                        a[v].push(dancer)
                    }
                }
            });

            return a;
        },{});
        //console.log('distinct total acess')
        //console.log(distinctTotalsAcess)

        // loop through distinct totals and acess if two or more numbers have the same total
        Object.keys(distinctTotalsAcess).map(total=>{
            // if total has only one dancer we can place that dancer to the next availble position
            if(distinctTotalsAcess[total].length ===1){
                const dancer = distinctTotalsAcess[total];
                const place = availablePlacings[0];

                finalPlacing[dancer]=place;
                availablePlacings = availablePlacings.filter(p=>{
                    if(p !== place){
                        return p
                    }
                });
                currentPlace=availablePlacings[0];

            }
            //if 2 or more are tied for a place
            else{
                handleMultiRule10(distinctTotalsAcess[total]);
            }
        });
    }

    if(Object.keys(finalPlacing).length ===dancers.length){
        return ({
            result:finalPlacing,
            rule11TableData:rule11Table,
            totals:totals,
            danceResults:resultsSum
        })
    }
    /*
    console.log('------------------')
    console.log('result')
    console.log(finalPlacing);
    console.log('rule 11 table data');
    console.log(rule11Table)*/
};

function finalSummary(section){
    return FinalSummary(section)
}


//multi Dance functions
//return table
export const MultiDanceSection=(sectionData)=>{

    const rounds = sectionData.rounds;
    const overAllDances=[];
    const overAllDancers=[];
    const overAllMarks={};
    let overAllRoundData={};

    function dancersCheck(dancers){
        dancers.map(dancer=>{
            if(!overAllDancers.includes(dancer)){
                overAllDancers.push(dancer)
            }
        })

    }

    function AddSortedMarks(roundData, dance){
           overAllMarks[dance]=SortMarks(roundData)[dance]
    }

    function BuildFinalData(){
        overAllRoundData={
            dances:overAllDances,
            dancers:overAllDancers,
            marks:overAllMarks
        };

        return(makeFinalSumTable(overAllRoundData));

    }

    return (
        <div>
            {Object.keys(rounds).map(round=>{
                const roundData=rounds[round];
                overAllDances.push(roundData.dances[0]);
                const dancers = roundData.activeList;
                dancersCheck(dancers);
                AddSortedMarks(roundData,roundData.dances[0]);
                return makeDanceTable(roundData,round+'id')

            })}
            <div>
                <h5>Final Summary</h5>
            {overAllDances.length === Object.keys(rounds).length?BuildFinalData():''}
            </div>
        </div>
    );




};

//return result
export const MultiDanceSectionResult=(sectionData)=>{
    const rounds = sectionData.rounds;
    let overAllMarks={};
    Object.keys(rounds).map(round=>{
        const roundData=rounds[round];
        overAllMarks[roundData.dances[0]]=SortMarks(roundData)[roundData.dances[0]];
    });

    return finalSummary(overAllMarks).result;


};

function makeDanceTable(roundData,key){
    const sortedMarks=SortMarks(roundData);
    const dances = roundData.dances;
    const marks = roundData.marks;
    const startList = roundData.activeList;
    const adjuds = Object.keys(marks);
    return (
        <div key={key} style={{paddingTop:25,paddingBottom:25}}>
            <h4>{dances[0]}</h4>
            <style>{`
                           @media print {
                                   table{
                                   position: relative;
                                        page-break-inside:auto
                                        page-break-after:auto;
                                    }
                                    th{
                                    position: relative;
                                        page-break-inside:avoid;
                                        page-break-after:auto;
                                        page-break-before:auto
                                    }
                                    tr{
                                    position: relative;
                                        page-break-inside:avoid;
                                        page-break-after:auto;
                                        page-break-before:auto
                                    }
                                    td{
                                    position: relative;
                                        page-break-inside:avoid;
                                        page-break-after:auto;
                                        page-break-before:auto
                                    }
                           }                            
                            th{
                            position: relative;
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                             fontSize:10;
                              page-break-inside:avoid;
                            page-break-after:auto;
                            page-break-before:auto
                            }
                            td{
                            position: relative;
                             page-break-inside:avoid;
                             page-break-after:auto;
                             page-break-before:auto;
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                              fontSize:10
                            }
                            table{
                            position: relative;
                                border-collapse :collapse;
                                page-break-after:auto;
                                page-break-inside:auto
                                }
                            
                          `}
            </style>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    {adjuds.map(adj=>{
                        return (
                            <th key={adj}>{adj}</th>
                        )
                    })}

                    {startList.map((d,i)=>{
                        return (
                            <th key={i}>(1-{i+1})</th>
                        )
                    })}
                    <th>Result</th>
                </tr>
                </thead>
                <tbody>
                    {makePlacingsTable(sortedMarks[dances[0]],dances[0])}
                </tbody>
            </table>
        </div>
    )

}

function makeFinalSumTable(data){
    const {dances,dancers,marks}=data;
    const finalPlacing = finalSummary(marks);
    return (
        <div>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    {dances.map(dance=>{
                        return <th key={'fs'+dance}>{dance.charAt(0)}</th>
                    })}
                    <th>Totals</th>
                    <th>Result</th>
                </tr>
                </thead>
                <tbody>
                {dancers.map(dancer=>{
                    return (
                        <tr key={'fn'+dancer}>
                            <td>{dancer}</td>
                            {dances.map((dance,i)=>{
                                return <td key={dance+i+dancer}>{finalPlacing.danceResults[dance][dancer]}</td>
                            })}
                            <td>{finalPlacing.totals[dancer]}</td>
                            <td>{finalPlacing.result[dancer]}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {makeRule11Table(finalPlacing.rule11TableData)}
        </div>
    )
}




