import React from "react";
import {connect}from 'react-redux';
// components
import history from "../history";
import Tabs from "../components/CustomTabs/CustomTabs";
import {FormatListBulleted, PlaylistAddCheck} from "@material-ui/icons";
import ViewFinalMarks from "../Marks/ViewFinalMarks";
import ViewRoundMarks from "../Marks/ViewRoundMarks";
import ViewSocialMarks from "../Marks/ViewSocialMarks";
import ViewFinalMarksResult from "../Marks/ViewFinalMarksResult";
import ViewRoundResult from "../Marks/ViewRoundResult";
import ViewMultiSectionMarks from "../Marks/ViewMultiSectionMarks";
import VIewMultiSectionResults from "../Marks/VIewMultiSectionResults";
import CenterHeader from "../components/Header/CenterHeader";
import WhiteFooter from "../components/Footer/WhiteFooter";

class ViewEventResults extends React.Component{

    componentDidMount() {
        window.scroll(0,0);
        if(!this.props.eventData){
            history.push('/')
        }
    }

    renderMarks=()=>{
        const {eventData,sectionId,roundId} = this.props;

        const sectionData = eventData.sections[sectionId];
        const sectionType = sectionData.type;
        const roundData = sectionData.rounds[roundId];
        const roundType = roundData.roundType;

        if(sectionType==='competitive' || sectionType==='multiDance'){
            if(roundType==='Final'|| roundType==='Uncontested'){

                return (
                    <ViewFinalMarks
                        sectionData={sectionData}
                        roundData={roundData}
                        eventInfo={eventData.info}
                    />
                )
            }else{
                return (
                    <ViewRoundMarks
                        sectionData={sectionData}
                        roundData={roundData}
                        eventInfo={eventData.info}
                    />
                )
            }



        }
        else if(sectionType==='social'){
            return (
                <ViewSocialMarks
                    sectionData={sectionData}
                    roundData={roundData}
                    eventInfo={eventData.info}
                />
            )
        }
    };

    renderResult=()=>{
        const {eventData,sectionId,roundId} = this.props;

        const sectionData = eventData.sections[sectionId];
        const sectionType = sectionData.type;
        const roundData = sectionData.rounds[roundId];
        const roundType = roundData.roundType;

        if(sectionType==='competitive'|| sectionType==='multiDance'){
            if(roundType==='Final'|| roundType==='Uncontested'){
                return (
                    <ViewFinalMarksResult
                        sectionData={sectionData}
                        roundData={roundData}
                        eventInfo={eventData.info}
                    />
                )
            }else{

                return (
                    <ViewRoundResult
                        sectionData={sectionData}
                        roundData={roundData}
                        eventInfo={eventData.info}
                    />
                )

            }
        }else{
            return (
                <ViewFinalMarksResult
                    sectionData={sectionData}
                    roundData={roundData}
                    eventInfo={eventData.info}
                />
            )
        }
    };

    renderSection=()=>{
        const {eventData,sectionId,roundId} = this.props;
        if(eventData!== null){
            const sectionData = eventData.sections[sectionId];
            if(roundId!=='multi'){
                return (
                    <div>
                        <Tabs
                            headerColor='info'
                            tabs={[
                                {
                                    tabName:'Marks',
                                    tabIcon:PlaylistAddCheck,
                                    tabContent:this.renderMarks()
                                },
                                {
                                    tabName:'Result',
                                    tabIcon:FormatListBulleted,
                                    tabContent:this.renderResult()
                                }
                            ]}

                        />
                    </div>
                )
            }else{
                return (
                    <div>
                        <Tabs
                            headerColor='info'
                            tabs={[
                                {
                                    tabName:'Marks',
                                    tabIcon:PlaylistAddCheck,
                                    tabContent:<ViewMultiSectionMarks eventInfo={this.props.eventData.info} sectionData={sectionData} />
                                },
                                {
                                    tabName:'Result',
                                    tabIcon:FormatListBulleted,
                                    tabContent:<VIewMultiSectionResults eventInfo={this.props.eventData.info} sectionData={sectionData} />
                                }
                            ]}
                        />
                    </div>
                )
            }
        }else{
            //history.push('/results')
        }
    };
    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
                <CenterHeader/>
                <div style={{marginTop:100, paddingLeft:'3%', paddingRight:'3%'}}>
                    {this.renderSection()}
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}
const mapStateToProps=(state,ownProps)=>{
    const Id = ownProps.match.params.id;
    const [sectionId,roundId]=Id.split('&');
    return (
        {
            Id,
            sectionId,
            roundId,
            eventData:state.eventResult.eventData
        })

};
export default connect(mapStateToProps)(ViewEventResults);
