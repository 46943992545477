import React from "react";
import {connect}from 'react-redux';
import history from "../history";
import {setPublishedEvent} from "../Store/Actions";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import {InputAdornment, Tooltip} from "@material-ui/core";
import Button from "../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import {RemoveRedEye,Search} from "@material-ui/icons";
import Table from "../components/Table/Table";
import CustomInput from "../components/CustomInput/CustomInput";
import Tabs from "../components/CustomTabs/CustomTabs";
import CenterHeader from "../components/Header/CenterHeader";
import WhiteFooter from "../components/Footer/WhiteFooter";
import LinearLoading from "../components/Loading/LinearLoading";

class ResultsEvent extends React.Component{

    state={
        loading:true,
        errLoading:'',
        dataLoaded:false,
        search:'',
        searchMulti:''
    };

    componentDidMount=()=>{
        const evenId = this.props.eventId;
        this.props.setPublishedEvent(evenId,res=>{
            res.status===1?
                this.setState({loading:false,errLoading:''}):
                this.setState({loading:false,errLoading:res.msg})
        })
    };

    renderSections=()=>{
        const sections =this.props.eventData.sections;
        const search = this.state.search.toLocaleLowerCase();
        const items = Object.keys(sections).reduce((a,v)=>{

            const {title,rounds,dancers,type}=sections[v];


            if(rounds!==undefined){
                Object.keys(rounds).map(roundId=>{
                    const {itemNo,roundType,status,dances}=sections[v].rounds[roundId];

                    if(title.toLocaleLowerCase().includes(search)|| type.toLocaleLowerCase().includes(search)
                        || roundType.toLocaleLowerCase().includes(search)|| itemNo.includes(search)
                    ){
                        if(status==='checking'|| status ==='completed'){
                            a[itemNo]={
                                title,
                                roundType,
                                dancers,
                                type,
                                dances,
                                status,
                                roundId,
                                sectionId:v
                            }
                        }

                    }
                })
            }
            return a;
        },[]);

        const tableData = Object.keys(items).sort((a,b)=>a-b).reduce((a,v)=>{

            const { title, roundType, dancers, type, dances, status, roundId, sectionId}=items[v];

            a.push([
                v,
                type ==='multiDance'?title+' - '+roundType+' '+dances[0]:title+'-'+roundType,
                type,
                Object.keys(dancers).length,
                (
                    <Tooltip title={'View results'}>
                        <Link to={`/event/section/${sectionId+'&'+roundId}`}>
                            <Button color='info' size='sm' justIcon round>
                                <RemoveRedEye/>
                            </Button>
                        </Link>
                    </Tooltip>
                )
            ]);

            return a;
        },[]);

        return (
            <Table
                tableHeaderColor={'info'}
                tableData={tableData}
                tableHead={['Item No','Title','Type','No Dancers','Action']}
            />
        )
    };

    renderMultiSections=()=>{
        const sections =this.props.eventData.sections;
        const search = this.state.searchMulti.toLocaleLowerCase();

        const tableData = Object.keys(sections).reduce((a,v)=>{

            const {type,title,rounds,}=sections[v];

            if(type==='multiDance'){
                if(rounds!==undefined){
                    const roundCheck = Object.keys(rounds).reduce((ar,val)=>{
                        const {status}=rounds[val];
                        if(status==='complete'|| status==='checking'){
                            ar.push(val)
                        }
                        return ar;
                    },[]);
                    if(roundCheck.length === Object.keys(rounds).length){
                        if(title.toLocaleLowerCase().includes(search)){
                            a.push([
                                title,
                                Object.keys(rounds).length,
                                (
                                    <Tooltip title={'View Sections'}>
                                        <Link to={`/event/section/${v+'&'+'multi'}`}>
                                            <Button  color='info' size='sm' justIcon round>
                                                <RemoveRedEye/>
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                )
                            ])
                        }
                    }
                }

            }
            return a;
        },[]);

        if(tableData.length>0){
            return (
                <Table
                    tableHeaderColor={'info'}
                    tableHead={['Title','No Dances','Action']}
                    tableData={tableData}
                />
            )
        }else{
            return (
                <div>
                    There are no multidance sections in this event.
                </div>
            )
        }



    };

    handleChange = name=>event=>{
        const value = event.target.value;
        this.setState({[name]:value})
    };

    renderData=()=>{
        const {loaded,eventData}=this.props;

        if(loaded){
            const {title,logo,date,venue,scrutineer,chairPerson,organizer,organization}=eventData.info;
            return (
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <GridContainer>
                                    <GridItem xs={12} md={6}  container justify='center' alignItems='center'>
                                        <img alt={'event-logo'} src={logo.url} height={150}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6} container justify='center' alignItems='center'>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td colSpan={2}><h5>{title}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><b>Date:</b></td>
                                                <td>{date}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Venue:</b></td>
                                                <td>{venue}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Chairperson</b></td>
                                                <td>{chairPerson}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Scrutineer</b></td>
                                                <td>{scrutineer.name}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Organizer</b></td>
                                                <td>{organizer.name}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{organizer.contact}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{organization}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <Tabs
                                    headerColor={'info'}
                                    tabs={[
                                        {
                                            tabName:'All Results',
                                            tabContent:(
                                                <GridContainer>
                                                    <GridItem xs={12} md={8}/>
                                                    <GridItem xs={12} md={4}>
                                                        <CustomInput
                                                            style={{justifyContent:'flex-start'}}
                                                            value={this.state.search}
                                                            labelText={'Search'}
                                                            id={'search'}
                                                            formControlProps={{
                                                                fullWidth: false
                                                            }}
                                                            inputProps={{
                                                                autoComplete:'off',
                                                                value:this.state.search,
                                                                onChange:(this.handleChange('search')),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search/>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12}>
                                                        {this.renderSections()}
                                                    </GridItem>
                                                </GridContainer>
                                            )

                                        },
                                        {
                                            tabName:'MultiDance Sections',
                                            tabContent:(
                                                <GridContainer>
                                                    <GridItem xs={12} md={8}/>
                                                    <GridItem xs={12} md={4}>
                                                        <CustomInput
                                                            style={{justifyContent:'flex-start'}}
                                                            value={this.state.searchMulti}
                                                            labelText={'Search'}
                                                            id={'searchMulti'}
                                                            formControlProps={{
                                                                fullWidth: false
                                                            }}
                                                            inputProps={{
                                                                autoComplete:'off',
                                                                value:this.state.search,
                                                                onChange:(this.handleChange('searchMulti')),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search/>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12}>
                                                        {this.renderMultiSections()}
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>

                    </GridItem>
                </GridContainer>
            )
        }
    };


    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
                <CenterHeader/>
                <div style={{marginTop:100, paddingLeft:'3%', paddingRight:'3%',marginBottom:10}}>
                    {this.state.loading ? <LinearLoading show={true}/>:null}

                    {this.renderData()}
                </div>
               <WhiteFooter/>
            </div>
        )
    }

}

const mapStateToProps=(state,ownProps)=>{
    const eventId = ownProps.match.params.id;
    let eventActId=null;
    if(eventId!==undefined){
        eventActId=eventId;
    }else{
        history.push('/')
    }

    return {
        eventData:state.eventResult.eventData,
        loaded:state.eventResult.loaded,
        eventId:eventActId
    }

};

export default connect(mapStateToProps,{setPublishedEvent})(ResultsEvent);
