import {combineReducers} from "redux";

import resultsEvents from './AllEventsReducer';
import eventResult from './EventReducer';
import Disclaimers from './DisclaimerReducer';
const index={
    resultsEvents,
    eventResult,
    Disclaimers
}

export default combineReducers(index);