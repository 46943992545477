import React from 'react';

export const unContestedTable=(roundData)=>{

    const {marks,activeList,dances}=roundData;
    const adjuds = Object.keys(marks);
    const dancers = activeList;

    const sortedMarks = SortMarks(roundData);

    const danceTotal = unConstestMarks(sortedMarks);

    return (
        <div>
            <style>{`
                    @media print{
                            table{
                                page-break-inside:auto;
                                page-break-after:auto;
                                position: relative;
                            }
                            th{
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto;
                                position: relative;
                            }
                            tr{
                            position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            td{
                            position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                        }
                                                       
                            th{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                             fontSize:10;
                              position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            td{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                              fontSize:10;
                               position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            table{
                                border-collapse :collapse;
                                 position: relative;
                                page-break-inside:avoid;
                                page-break-after:auto;
                                page-break-before:auto
                            }
                            
                          `}
            </style>
            {normalUncontestedDanceTable(sortedMarks,danceTotal, dances,adjuds,dancers)}
            <div>
                <h4>Final Summary</h4>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            {dances.map(dance=>(
                                <th key={dance+dance}>{dance.charAt(0).toUpperCase()}</th>
                            ))}
                            <th>Total</th>
                            <th>Place</th>
                        </tr>
                    </thead>
                    <tbody>
                    {dancers.map(dancer=>{

                        let finalTotal =0;



                        return (
                            <tr key={dancer+dancer}>
                                <td>{dancer}</td>
                                {dances.map((dance,i)=>{
                                    finalTotal +=danceTotal[dance][dancer];

                                    return (
                                        <td key={dance+i}>{danceTotal[dance][dancer]}</td>
                                    )
                                })}
                                <td>{finalTotal}</td>
                                <td>{Math.round(finalTotal/dances.length)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};


function unConstestMarks(marks){

    const dances =Object.keys(marks);

    return dances.reduce((a,v)=>{

        a[v] = calculateSingle(marks[v]);
        return a;
    },{})
}

function calculateSingle(marks){

    const dancers = Object.keys(marks);
    const adjNum = marks[dancers[0]].length;

    return dancers.reduce((a,v)=>{

        const total=marks[v].reduce((ar,val)=>{

            ar +=parseInt(val);

            return ar
        },0);

        a[v]=Math.round(total/adjNum);


        return a;
    },{})

}

function normalUncontestedDanceTable(sortedMarks,danceTotal, dances,adjuds,dancers) {

    return (
        <div >
            {dances.map(dance => {
                return (
                    <div key={dance}>
                        <h4>{dance.toUpperCase()}</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>#</th>
                                {adjuds.map(adj => (
                                    <th key={adj + dance}>{adj}</th>
                                ))}
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dancers.map(dancer => {
                                return (
                                    <tr key={dancer + dance}>
                                        <td>{dancer}</td>
                                        {adjuds.map((adj, i) => {

                                            return <td key={adj + i + dance}>{sortedMarks[dance][dancer][i]}</td>
                                        })}
                                        <td>{danceTotal[dance][dancer]}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )
            })}

        </div>
    );
}



function SortMarks (roundData){
    const marks = roundData.marks;
    const dances = roundData.dances;
    const startList = roundData.activeList;
    const adjuds = Object.keys(marks);

    return dances.reduce((a,dance)=>{
        a[dance]=startList.reduce((ar,dancer)=>{

            ar[dancer] = adjuds.reduce((arr,mark)=>{
                arr.push(marks[mark][dance][dancer]);
                return arr
            },[]);
            return ar
        },{});

        return a;
    },{});
}


export const UncontestFinalSpecialTable=(roundData)=>{

    const {marks,activeList,dances}=roundData;
    const adjuds = Object.keys(marks);
    const dancers = activeList;

    const sortedMarks = SortMarks(roundData);
    const danceTotal = unConstestMarks(sortedMarks);
    return (
        <div>
            <style>{`
                                                       
                            th{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                             fontSize:10
                            }
                            td{
                             border: 1px solid #99989C;
                             text-align: center;
                             padding:0.5vmin;
                              fontSize:10
                            }
                            table{
                                border-collapse :collapse
                            }
                            
                          `}
            </style>
            {normalUncontestedDanceTable(sortedMarks,danceTotal, dances,adjuds,dancers)}
            {buildFinalTableSpecial(danceTotal)}
        </div>
    )
};

function finalTotalUncontested(danceTotal){

   const dances = Object.keys(danceTotal);

   const dancers = Object.keys(danceTotal[dances[0]]);

    const dancesLength = dances.length;
   const total = dancers.reduce((a,v)=>{

      a[v]=dances.reduce((ar,dance)=>{

          if(dance !=='exhibition'&&dance !=='showcase'&&dance !=='showdance'&&dance !=='solo spot'){

              ar += parseInt(danceTotal[dance][v])

          }else{
                const value =parseInt(danceTotal[dance][v]);

              if(value >=80){
                  ar+=1
              }else if(value>=70){
                  ar+=2
              }else{
                  ar+=3
              }

          }


         return ar
      },0);

       return a;
   },{});

    const place = dancers.reduce((a,v)=>{

       a[v]=Math.round(total[v]/dancesLength);


        return a;
    },{});


   return {total,place}

}


function buildFinalTableSpecial(danceTotal){

    const dances = Object.keys(danceTotal);

    const dancers = Object.keys(danceTotal[dances[0]]);

    const finalTotals = finalTotalUncontested(danceTotal);

    return (
        <div>
            <h4>Final Summary</h4>
            <h5>% Scoring</h5>
            <p>100%-80% = 1 79%-70%= 2 69%-0% = 3  </p>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    {dances.map(dance=>(
                        <th key={dance+'finalHEad'}>{dance.charAt(0).toUpperCase()}</th>
                    ))}
                    <th>Total</th>
                    <th>Place</th>
                </tr>
                </thead>
                <tbody>
                {dancers.map(dancer=>{
                    return (
                        <tr key={dancer+'finalSum'}>
                            <td>{dancer}</td>
                            {dances.map(dance=>(
                                <td key={dance+'finalsum'}>{danceTotal[dance][dancer]}</td>
                            ))}
                            <td>{finalTotals.total[dancer]}</td>
                            <td>{finalTotals.place[dancer]}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export const uncontestFinalMarks =(roundData)=>{
    const {marks,activeList,dances}=roundData;
    const adjuds = Object.keys(marks);
    const dancers = activeList;

    const sortedMarks = SortMarks(roundData);

    const danceTotal = unConstestMarks(sortedMarks);
    const finalTotals = dancers.reduce((a,v)=>{

       a[v] = dances.reduce((ar,val)=>{
           if(val ==='exhibition' || val ==='showcase'||val ==='showdance'|| val ==='solo spot'){
               const value = parseInt(danceTotal[val][v]);

               if(value >=80){
                   ar+=1
               }else if(value>=70){
                   ar+=2
               }else{
                   ar+=3
               }

           }else{
               ar += parseInt(danceTotal[val][v]);
           }

           return ar;
       },0);


        return a;
    },{});

    return dancers.reduce((a,v)=>{

        a[v]=Math.round(finalTotals[v]/dances.length);

       return a;
    },{});
};




