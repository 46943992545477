import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {renderPanel} from "./Functions";
import {marksTableFinal} from "./Alogrithms/finalAlgo";
import {unContestedTable, UncontestFinalSpecialTable} from "./Alogrithms/uncontestedAlgo";
import {specialDanceTable} from "./Alogrithms/specialFunctions";

class ViewSocialMarks extends React.Component{

    renderMarks=(roundData)=>{
        const {roundType,dances}=roundData;
        if(roundType==='Contested'){
            // treat as normal final
            return marksTableFinal(roundData);
        }else if(roundType==='Uncontested'){
            if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                return UncontestFinalSpecialTable(roundData);
            }else{
                return unContestedTable(roundData)
            }
        }
    };

    renderSpecialMarks=(specialMarks)=>{
        if(specialMarks!== undefined){
            return (specialDanceTable(specialMarks));
        }
    };


    render(){
        const {title,date,venue,organization,organizer,scrutineer,chairperson,adjudicators,logo}=this.props.eventInfo;
        const {type,}=this.props.sectionData;
        const {dances,roundType,itemNo,panel,specialMarks,roundNo}=this.props.roundData;
        return (
            <GridContainer>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    <img src={logo.url} alt={'logo'} height={100}/>
                </GridItem>
                <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                    Title: {title}<br/>
                    Date:{date}<br/>
                    Venue:{venue}<br/>
                    Organization:{organization}<br/>
                    Organizer:{organizer.name}<br/>
                    Chairperson:{chairperson}<br/>
                    Scrutineer:{scrutineer.name}
                </GridItem>
                <GridItem xs={12}>
                    <h5>{itemNo+' '+title+'-'+roundType}</h5>
                    <h6>Round No:{roundNo}</h6>
                </GridItem>
                <GridItem xs={12}>
                    {renderPanel(panel,adjudicators)}
                </GridItem>
                <GridItem xs={12}>
                    {this.renderMarks(this.props.roundData)}
                </GridItem>
                <GridItem xs={12}>
                    {
                        dances.includes('exhibition') ||
                        dances.includes('showcase')||
                        dances.includes('showdance')||
                        dances.includes('solo spot') ?
                            <GridItem xs={12}>
                                <h3>TCIS Marks</h3>
                                <p>T-Technique C-Choreography I-Image S-Show </p>
                                {this.renderSpecialMarks(specialMarks)}
                            </GridItem>:null
                    }

                </GridItem>

            </GridContainer>
        )
    }
}export default ViewSocialMarks;